import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { useResetFormOnCloseModal } from '@app/components/forms/ControlForm/useResetFormOnCloseModal';
import { VideoTableRow } from '@app/api/video.api';
import ReactPlayer from 'react-player';
interface AddUserFormModalProps {
  data: VideoTableRow;
  open: boolean;
  onCancel: () => void;
}

interface OptionValue {
  key: string;
  value: string;
}

export const VideoDetailModal: React.FC<AddUserFormModalProps> = ({ open, onCancel, data }) => {
  const [form] = BaseForm.useForm();
  const { t } = useTranslation();
  // const dispatch = useAppDispatch();
  // const [isLoading, setLoading] = useState(false);
  // const [categories, setCategories] = useState<OptionValue[]>([]);
  // const [cateSelected, setCateSelected] = useState<OptionValue>();
  // const [progress, setProgress] = useState(0);

  useResetFormOnCloseModal({
    form,
    open,
  });

  // const onOk = () => {
  //   form.submit();
  // };

  // useEffect(() => {
  //   getCategoryList({ page: 1, limit: 50 })
  //     .then((res) => {
  //       setCategories(res.data.data.map((v: CategoryModel) => ({ key: v.id, value: v.name })));
  //     })
  //     .finally(() => setLoading(false));
  // }, []);

  return (
    <Modal
      width={700}
      open={open}
      title="Detail video"
      onOk={onCancel}
      onCancel={onCancel}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <BaseForm layout="vertical">
        <BaseForm.Item label="Thumbnail">
          <img src={data.thumbnail} alt={data.name} width={300} height={150} />
          {/* <ReactPlayer url='https://www.youtube.com/watch?v=ysz5S6PUM-U' light={data.thumbnail} /> */}
        </BaseForm.Item>
        <BaseForm.Item name="title" label="Title">
          {data.title}
        </BaseForm.Item>
        <BaseForm.Item label={t('vimeo.vimeoId')}>{data.vimeoId}</BaseForm.Item>
        <BaseForm.Item
          name="description"
          label="Description"
          rules={[{ required: false, message: t('common.requiredField') }]}
        >
          {data.description}
        </BaseForm.Item>
        {/* <BaseForm.Item label="Short description">{data.description_short}</BaseForm.Item> */}
        <BaseForm.Item label="Categories">
          {data.categories && data.categories.length > 0 ? data.categories.map((value) => value.title).join(', ') : ''}
        </BaseForm.Item>
      </BaseForm>
    </Modal>
  );
};
