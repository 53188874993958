import { PrepareAction, createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  CategoryListRequest,
  NewCategoryData,
  UpdateCategoryData,
  addNewCategory,
  getCategoryList,
  getCategoryFirebaseList,
  deleteCategory,
} from '@app/api/category.api';
import { CategoryModel } from '@app/domain/CategoryModel';
import { updateCategory } from '@app/api/category.api';

export interface CategoryState {
  categories: CategoryModel[] | [];
  categoryFirebase: string[] | [];
}

const initialState: CategoryState = {
  categories: [],
  categoryFirebase: [],
};

export const getCategories = createAsyncThunk(
  'category/list',
  async (videoPayload: CategoryListRequest, { dispatch }) =>
    getCategoryList(videoPayload).then((res) => {
      dispatch(res.data.data);
      return res.data.data;
    }),
);

export const getCategoryFirebase = createAsyncThunk('category-firebase/list', async ({}, { dispatch }) =>
  getCategoryFirebaseList().then((res) => {
    dispatch(res.data.data);
    return res.data.data;
  }),
);

export const newCategory = createAction<PrepareAction<CategoryModel>>('category/newCategory', (newCategory) => {
  return {
    payload: newCategory,
  };
});

export const doAddNewCategory = createAsyncThunk(
  'category/doAddNewCategory',
  async (newCategoryData: NewCategoryData) => addNewCategory(newCategoryData),
);

export const doUpdateCategory = createAsyncThunk(
  'category/doUpdateCategory',
  async (updateCategoryData: UpdateCategoryData) => updateCategory(updateCategoryData.id, updateCategoryData),
);

export const doDeleteCategory = createAsyncThunk('category/doDeleteCategory', async (id: number) => deleteCategory(id));

export const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCategories.fulfilled, (state, action) => {
      state.categories = action.payload;
    });
    builder.addCase(getCategoryFirebase.fulfilled, (state, action) => {
      state.categoryFirebase = action.payload;
    });
    builder.addCase(doAddNewCategory.fulfilled, (state, action) => {
      state.categories = [];
    });
  },
});

export default categorySlice.reducer;
