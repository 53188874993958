import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Input } from '@app/components/common/inputs/Input/Input';
import { notificationController } from '@app/controllers/notificationController';
import { useResetFormOnCloseModal } from '@app/components/forms/ControlForm/useResetFormOnCloseModal';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { Pagination, getCategoryList } from '@app/api/category.api';
import { Upload } from '@app/components/common/Upload/Upload';
import { UploadOutlined } from '@ant-design/icons';
import { Button } from '@app/components/common/buttons/Button/Button';
import axios from 'axios';
import { CategoryModel } from '@app/domain/CategoryModel';
import { doUpdateVideo } from '@app/store/slices/videoSlice';
import { UpdateVideoData, VimeoSelectTableRow, VideoTableRow, getVimeoVideoList, VimeoVideo } from '@app/api/video.api';
import TextArea from 'antd/lib/input/TextArea';
import { CateModel, VideoModel } from '@app/domain/VideoModel';
import { resizeThumbnail } from '@app/utils/utils';
import { Table } from '@app/components/common/Table/Table';
import { ColumnsType, TableRowSelection } from 'antd/es/table/interface';
import { v4 as uuidv4 } from 'uuid';
import { Select } from '@app/components/common/selects/Select/Select';

interface UpdateUserFormModalProps {
  data: VideoTableRow;
  open: boolean;
  onCancel: () => void;
  onFinish: () => void;
}

interface OptionValue {
  id: number;
  name: string;
  title?: string;
}

const initialInfoValues: VideoModel = {
  id: -1,
  title: '',
  name: '',
};

const initialPagination: Pagination = {
  current: 1,
  pageSize: 10,
  total: 0,
};

const initialVimeoPagination: Pagination = {
  current: 1,
  pageSize: 25,
  total: 0,
};

export const UpdateVideoFormModal: React.FC<UpdateUserFormModalProps> = ({ open, onCancel, onFinish, data }) => {
  const [form] = BaseForm.useForm();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const [tableData, setTableData] = useState<{
    data: OptionValue[];
    pagination: Pagination;
    loading: boolean;
  }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });
  const [selectedCates, setSelectedCates] = useState<CateModel[]>(data.categories);
  const [selectedRowKeys, setSelectedRowKeys] = useState<{ [key: string]: number[] }>({});
  const [selectedCategories, setSelectedCategories] = useState<{ [key: string]: OptionValue[] }>({});

  const [vimeoTableData, setVimeoTableData] = useState<{
    data: VimeoSelectTableRow[];
    pagination: Pagination;
    loading: boolean;
  }>({
    data: [],
    pagination: initialVimeoPagination,
    loading: false,
  });
  const [selectedVimeoId, setSelectedVimeoId] = useState<string | undefined>(data.vimeoId);

  useResetFormOnCloseModal({
    form,
    open,
  });

  const onOk = async () => {
    await syncCurrentPageCategoriesToForm();
    form.submit();
  };

  const fetchCategories = useCallback(
    (pagination: Pagination) => {
      setTableData((tableData) => ({ ...tableData, loading: true }));
      getCategoryList({ page: pagination.current, limit: pagination.pageSize }).then((res) => {
        const _tableData: OptionValue[] = res.data.data.map((v: CategoryModel) => ({
          id: v.id,
          name: v.name,
          title: v.title,
        }));
        setTableData({
          data: _tableData,
          pagination: { ...pagination, total: res.data.total },
          loading: false,
        });

        if (!selectedRowKeys[pagination.current ?? 0]) {
          // sync current page categories id of video to current state
          const _selectedCategories = selectedCates.filter((value) =>
            _tableData.map((_value) => _value.id).includes(value.id),
          );
          setSelectedRowKeys((value) => ({
            ...value,
            [`${pagination.current ?? 0}`]: _selectedCategories.map((value) => value.id),
          }));

          // unload selected cates to current state
          const _selectedCates = selectedCates.filter(
            (value) => !_selectedCategories.map((_value) => _value.id).includes(value.id),
          );
          setSelectedCates(_selectedCates);
        }
      });
    },
    [selectedCates, selectedRowKeys],
  );

  const fetchVimeoVideos = useCallback((pagination: Pagination) => {
    setVimeoTableData((tableData) => ({ ...tableData, loading: true }));
    getVimeoVideoList({ page: pagination.current, limit: pagination.pageSize }).then((res) => {
      const _tableData: VimeoSelectTableRow[] = res.data.data?.map((v: VimeoVideo) => ({
        name: v.name,
        vimeoId: v.uri.split('/').pop(),
      }));
      setVimeoTableData({
        data: _tableData,
        pagination: { ...pagination, total: res.data.total },
        loading: false,
      });
    });
  }, []);

  useEffect(() => {
    fetchCategories(initialPagination);
    fetchVimeoVideos(initialVimeoPagination);
  }, [fetchVimeoVideos]);

  const handleSubmit = (values: UpdateVideoData) => {
    setLoading(true);
    const _file: File | undefined = values.files ? values.files.pop() : undefined;
    let thumbnail = _file?.name ? _file?.name : data.thumbnail;
    const _thumbnail = (thumbnail || '').split('/').pop();
    thumbnail = decodeURI(_thumbnail || '');

    const _categories: OptionValue[] = [];
    Object.keys(selectedCategories).forEach((key) => {
      _categories.push(...selectedCategories[key]);
    });

    // load selected cates not visible to current state
    _categories.push(...selectedCates);
    // console.log(values.vimeoId);
    dispatch(
      doUpdateVideo({
        ...values,
        id: data.id,
        thumbnail: thumbnail !== '' ? `video/${thumbnail}` : '',
        categories: _categories,
      }),
    )
      .unwrap()
      .then(() => {
        setLoading(false);
        notificationController.success({
          message: 'Update video',
          description: 'Successfully',
        });
        onFinish();
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const normFile = (e = { fileList: [] }) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleUpload = async (options: { file: any; onSuccess?: any; onError?: any; onProgress?: any }) => {
    const { onSuccess, onError, file, onProgress } = options;

    const fmData = new FormData();
    const config = {
      headers: { 'content-type': 'multipart/form-data' },

      onUploadProgress: (event: any) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        setProgress(percent);
        if (percent === 100) {
          setTimeout(() => setProgress(0), 1000);
        }
        // onProgress({ percent: percent });
      },
    };
    const _uuid = uuidv4();
    try {
      const thumbnail = await resizeThumbnail(file);
      fmData.append('file', thumbnail as Blob, `${_uuid}.${(thumbnail as Blob).name?.split('.').pop() ?? ''}`);
      fmData.append('folderId', 'video');
      fmData.append('mediaId', (fmData.get('file') as File).name);
    } catch (error) {
      console.log('[resizeThumbnail] error:', error);
      fmData.append('file', file, `${_uuid}.${(file as Blob).name?.split('.').pop() ?? ''}`);
      fmData.append('folderId', 'video');
      fmData.append('mediaId', (fmData.get('file') as File).name);
    }

    try {
      const res = await axios.post(`${process.env.REACT_APP_BASE_URL}files/upload`, fmData, config);

      onSuccess('Ok');
      // console.log("server res: ", res);
      form.setFieldsValue({ thumbnail: res.data.path, files: [fmData.get('file')] });
    } catch (err) {
      // console.log("Eroor: ", err);
      // const error = new Error("Some error");
      onError({ err });
    }
  };

  const videoFormValues = useMemo(() => (data ? data : initialInfoValues), [data]);

  const syncCurrentPageCategoriesToForm = async () => {
    const _categories = tableData.data.filter((value) =>
      selectedRowKeys[`${tableData.pagination.current ?? 0}`]?.includes(+value.id),
    );

    setSelectedCategories((value) => ({ ...value, [`${tableData.pagination.current ?? 0}`]: _categories }));

    return _categories;
  };

  const handleTableChange = async (pagination: Pagination) => {
    await syncCurrentPageCategoriesToForm();
    fetchCategories(pagination);
  };

  const onSelectedRowKeysChange = (selectedRowKeys: React.Key[]) => {
    setSelectedRowKeys((value) => ({
      ...value,
      [`${tableData.pagination.current ?? 0}`]: selectedRowKeys as number[],
    }));
  };

  const rowSelection: TableRowSelection<OptionValue> = {
    selectedRowKeys: selectedRowKeys[`${tableData.pagination.current ?? 0}`] ?? [],
    onChange: onSelectedRowKeysChange,
  };

  const columns: ColumnsType<OptionValue> = [
    {
      title: 'Category',
      dataIndex: 'title',
      key: 'title',
      // sorter: (a: OptionValue, b: OptionValue) => (a.title ?? '').localeCompare(b.title ?? ''),
    },
  ];

  // vimeo table
  const handleVimeoTableChange = async (pagination: Pagination) => {
    // await syncCurrentPageCategoriesToForm();
    fetchVimeoVideos(pagination);
  };

  const onVimeoSelectedRowKeysChange = (selectedRowKeys: React.Key[]) => {
    const _id = selectedRowKeys[0] as string | undefined;
    setSelectedVimeoId(_id);
    _id && form.setFieldsValue({ vimeoId: _id });
  };

  const vimeoRowSelection: TableRowSelection<VimeoSelectTableRow> = {
    selectedRowKeys: selectedVimeoId ? [selectedVimeoId] : [],
    onChange: onVimeoSelectedRowKeysChange,
    type: 'radio',
  };

  const vimeoColumns: ColumnsType<VimeoSelectTableRow> = [
    {
      title: 'Vimeo id',
      dataIndex: 'vimeoId',
      key: 'vimeoId',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      // sorter: (a: VimeoSelectTableRow, b: VimeoSelectTableRow) => (a.name ?? '').localeCompare(b.name ?? ''),
    },
  ];

  return (
    <Modal
      title="Update video"
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      confirmLoading={isLoading}
      maskClosable={false}
    >
      <BaseForm
        initialValues={videoFormValues}
        form={form}
        layout="vertical"
        name="updateVideoForm"
        onFinish={handleSubmit}
      >
        <BaseForm.Item name="title" label="Title" rules={[{ required: true, message: t('common.requiredField') }]}>
          <Input />
        </BaseForm.Item>
        <BaseForm.Item
          name="description"
          label="Description"
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <TextArea rows={4} />
        </BaseForm.Item>
        <BaseForm.Item name="type" label="Type" rules={[{ required: true, message: t('common.requiredField') }]}>
          <Select
            style={{ minWidth: 120 }}
            placeholder="Select type"
            width="100%"
            options={[
              { key: '1', value: 'free', label: 'Free' },
              { key: '2', value: 'login-required', label: 'Login Required' },
              { key: '3', value: 'pro', label: 'Pro' },
            ]}
          />
        </BaseForm.Item>
        {/* <BaseForm.Item
          name="description_short"
          label="Short description"
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <TextArea rows={4} />
        </BaseForm.Item> */}
        <BaseForm.Item label={t('vimeo.currentVimeoId')}>{data.vimeoId ?? t('vimeo.noId')}</BaseForm.Item>
        <BaseForm.Item
          label={t('vimeo.vimeoId')}
          name="vimeoId"
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Table
            rowSelection={vimeoRowSelection}
            columns={vimeoColumns}
            dataSource={vimeoTableData.data}
            loading={vimeoTableData.loading}
            pagination={vimeoTableData.pagination}
            onChange={handleVimeoTableChange}
            scroll={{ x: 400 }}
            bordered
            rowKey={(record: VimeoSelectTableRow) => record.vimeoId}
          />
        </BaseForm.Item>
        <BaseForm.Item label="Category" name="category" rules={[{ required: false }]}>
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={tableData.data}
            loading={tableData.loading}
            pagination={tableData.pagination}
            onChange={handleTableChange}
            // scroll={{ x: 300 }}
            bordered
            rowKey={(record) => record.id}
          />
        </BaseForm.Item>
        <BaseForm.Item
          name="files"
          label="Thumbnail"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          rules={[{ required: false }]}
        >
          <Upload accept="image/*" maxCount={1} customRequest={handleUpload} listType="picture">
            <Button type="default" icon={<UploadOutlined />}>
              {t('forms.validationFormLabels.clickToUpload')}
            </Button>
          </Upload>
        </BaseForm.Item>
        <img src={data.thumbnail} alt={data.name} width={84} height={84} />
      </BaseForm>
    </Modal>
  );
};
