interface Language {
  id: number;
  name: string;
  code: string;
  countryCode?: string;
}

export const languages: Language[] = [
  {
    id: 1,
    name: 'English',
    code: 'en',
  },
  {
    id: 2,
    name: 'French',
    code: 'fr',
  },
  {
    id: 3,
    name: 'German',
    code: 'de',
  },
  {
    id: 4,
    name: 'Spanish',
    code: 'es',
  },
  {
    id: 5,
    name: 'Italian',
    code: 'it',
  },
];

export const languageOptions = languages.map((lang) => ({ label: lang.name, value: lang.code }));
