import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Upload } from 'antd';
import { UserModel } from '@app/domain/UserModel';
import * as S from './ProfileInfo.styles';
import { Button } from '@app/components/common/buttons/Button/Button';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import { doUpdateMe } from '@app/store/slices/authSlice';
import { FileModel } from '@app/domain/FileModel';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { notificationController } from '@app/controllers/notificationController';
import AvatarDefault from 'assets/images/default-avatar.jpg';
interface ProfileInfoProps {
  profileData: UserModel | null;
}

export const ProfileInfo: React.FC<ProfileInfoProps> = ({ profileData }) => {
  // const [fullness] = useState(90);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleUpload = async (options: { file: any; onSuccess?: any; onError?: any; onProgress?: any }) => {
    const { onSuccess, onError, file } = options;

    const fmData = new FormData();
    const config = {
      headers: { 'content-type': 'multipart/form-data' },

      onUploadProgress: (event: any) => {
        // const percent = Math.floor((event.loaded / event.total) * 100);
        // setProgress(percent);
        // if (percent === 100) {
        //   setTimeout(() => setProgress(0), 1000);
        // }
        // onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    fmData.append('file', file);
    fmData.append('folderId', 'avatar');
    fmData.append('mediaId', options.file.name);

    try {
      const res: FileModel = await axios
        .post(`${process.env.REACT_APP_BASE_URL}files/upload-image`, fmData, config)
        .then((res) => res.data);

      onSuccess('Ok');
      dispatch(doUpdateMe({ photo: res }))
        .unwrap()
        .then(() => {
          notificationController.success({
            message: 'Update avatar',
            description: 'Successfully',
          });
        })
        .catch((err) => onError({ err }));
    } catch (err) {
      onError({ err });
    }
  };

  return profileData ? (
    <S.Wrapper>
      <S.ImgWrapper>
        <Avatar shape="circle" src={profileData.photo ? profileData.photo.path : AvatarDefault} alt="Profile" />
      </S.ImgWrapper>
      <Upload accept="image/*" customRequest={handleUpload}>
        <Button type="default" icon={<UploadOutlined />}>
          {t('forms.validationFormLabels.clickToUpload')}
        </Button>
      </Upload>
      <S.Title>{`${profileData?.firstName} ${profileData?.lastName}`}</S.Title>
      <S.Subtitle>{profileData?.userName}</S.Subtitle>
      {/* <S.FullnessWrapper>
        <S.FullnessLine width={fullness}>{fullness}%</S.FullnessLine>
      </S.FullnessWrapper> */}
      {/* <S.Text>{t('profile.fullness')}</S.Text> */}
    </S.Wrapper>
  ) : null;
};
