import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { VideoModel } from '@app/domain/VideoModel';
import {
  addNewVideo,
  deleteVideo,
  getVideoList,
  NewVideoData,
  updateVideo,
  UpdateVideoData,
  VideoListRequest,
} from '@app/api/video.api';

export interface VideoState {
  videos: VideoModel[] | null;
}

const initialState: VideoState = {
  videos: [],
};

export const getVideos = createAsyncThunk('video/list', async (videoPayload: VideoListRequest, { dispatch }) =>
  getVideoList(videoPayload).then((res) => {
    dispatch(res.data.data);
    return res.data.data;
  }),
);

export const doAddNewVideo = createAsyncThunk('video/doAddNewVideo', async (newVideoData: NewVideoData) =>
  addNewVideo(newVideoData),
);

export const doUpdateVideo = createAsyncThunk('video/doUpdateVideo', async (updateVideoData: UpdateVideoData) =>
  updateVideo(updateVideoData.id, updateVideoData),
);

export const doDeleteVideo = createAsyncThunk('video/doDeleteVideo', async (id: number) => deleteVideo(id));

export const videoSlice = createSlice({
  name: 'video',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getVideos.fulfilled, (state, action) => {
      state.videos = action.payload;
    });
    // builder.addCase(doDeleteVideo.fulfilled, (state, action) => {
    // });
  },
});

export default videoSlice.reducer;
