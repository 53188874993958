import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { useResetFormOnCloseModal } from '@app/components/forms/ControlForm/useResetFormOnCloseModal';
import { CouponTableRow, GeneratorTableRow, getCouponList } from '@app/api/coupon.api';
import { Pagination } from '@app/api/category.api';
import { ColumnsType } from 'antd/es/table';
import { formatDate } from '@app/utils/utils';
import { Table } from '@app/components/common/Table/Table';
interface ViewCouponsModalProps {
  data: GeneratorTableRow;
  open: boolean;
  onCancel: () => void;
}

const initialPagination: Pagination = {
  current: 1,
  pageSize: 10,
  total: 0,
  defaultPageSize: 10,
  showSizeChanger: true,
  pageSizeOptions: ['10', '50', '100'],
};

export const ViewCouponsModal: React.FC<ViewCouponsModalProps> = ({ open, onCancel, data }) => {
  const [tableData, setTableData] = useState<{
    data: CouponTableRow[];
    pagination: Pagination;
    loading: boolean;
  }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });
  const [form] = BaseForm.useForm();

  const fetch = useCallback((pagination: Pagination) => {
    setTableData((tableData) => ({ ...tableData }));
    getCouponList({
      generatorId: data.id,
      page: pagination.current,
      limit: pagination.pageSize,
    }).then((res) => {
      setTableData({
        data: res.data.data,
        pagination: { ...pagination, total: res.data.total },
        loading: false,
      });
    });
  }, []);

  useEffect(() => {
    fetch(initialPagination);
  }, [data]);

  useResetFormOnCloseModal({
    form,
    open,
  });

  const handleTableChange = (pagination: Pagination) => {
    fetch(pagination);
  };

  const columns: ColumnsType<CouponTableRow> = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Redeemed At',
      dataIndex: 'redeemedAt',
      key: 'redeemedAt',
      render: (_, record) => formatDate(record.redeemedAt),
    },
    {
      title: 'Device Id',
      dataIndex: 'deviceId',
      key: 'deviceId',
    },
  ];

  return (
    <Modal
      width={700}
      open={open}
      title={`Coupon codes of ${data.prefix}`}
      onOk={onCancel}
      onCancel={onCancel}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <Table
        loading={tableData.loading}
        columns={columns}
        dataSource={tableData.data}
        pagination={{ ...tableData.pagination, style: { alignItems: 'center' } }}
        onChange={handleTableChange}
        scroll={{ x: 500 }}
        bordered
        rowKey={(record) => record.id}
      />
    </Modal>
  );
};
