import React, { useEffect, useState, useCallback } from 'react';
import { Image, Space, Tooltip } from 'antd';
import { Table } from 'components/common/Table/Table';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { useMounted } from '@app/hooks/useMounted';
import { Card } from '@app/components/common/Card/Card';
import {
  CategoryTableRow,
  Pagination,
  PaginationOrder,
  PaginationSortBy,
  getCategoryList,
} from '@app/api/category.api';
import { Button } from '@app/components/common/buttons/Button/Button';
import { CaretDownFilled, CaretUpFilled, PlusOutlined, SwapOutlined } from '@ant-design/icons';
import { AddCategoryFormModal } from '@app/components/category/NewCategoryForm/NewCategoryForm';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { UpdateCategoryFormModal } from '@app/components/category/UpdateCategoryForm/UpdateCategoryForm';
import { CategoryModel } from '@app/domain/CategoryModel';
import { doDeleteCategory } from '@app/store/slices/categorySlice';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { Popconfirm } from '@app/components/common/Popconfirm/Popconfirm';
import { notificationController } from '@app/controllers/notificationController';
import noImage from 'assets/images/no-image.png';
import { Avatar } from '@app/components/common/Avatar/Avatar';
import { getLanguageByCode } from '@app/utils/utils';

const initialPagination: Pagination = {
  current: 1,
  pageSize: 10,
  total: 0,
  defaultPageSize: 10,
  showSizeChanger: true,
  pageSizeOptions: ['10', '50', '100'],
};

const CategoryPage: React.FC = () => {
  const [tableData, setTableData] = useState<{
    data: CategoryTableRow[];
    pagination: Pagination;
    loading: boolean;
    sort: {
      sortBy: PaginationSortBy;
      order: PaginationOrder;
    };
  }>({
    data: [],
    pagination: initialPagination,
    loading: false,
    sort: {
      sortBy: 'title',
      order: 'asc',
    },
  });
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { isMounted } = useMounted();
  const [isCreateOpen, setIsCreateOpen] = useState<boolean>(false);
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const [itemSelected, setItemSelected] = useState<CategoryModel | null>(null);

  const fetch = useCallback(
    (pagination: Pagination, sort: { sortBy: PaginationSortBy; order: PaginationOrder }) => {
      setTableData((tableData) => ({ ...tableData, sort: sort, loading: true }));
      getCategoryList({
        page: pagination.current,
        limit: pagination.pageSize,
        sortBy: sort.sortBy,
        order: sort.order,
      }).then((res) => {
        setTableData({
          data: res.data.data,
          pagination: { ...pagination, total: res.data.total },
          loading: false,
          sort: sort,
        });
      });
    },
    [isMounted],
  );

  useEffect(() => {
    fetch(initialPagination, {
      sortBy: 'title',
      order: 'asc',
    });
  }, [fetch]);

  const handleEdit = (record: CategoryTableRow) => {
    setItemSelected(record);
    setTimeout(() => {
      setIsEditOpen(true);
    }, 250);
  };

  const handleDelete = (rowId: number) => {
    dispatch(doDeleteCategory(rowId))
      .unwrap()
      .then((res) => {
        notificationController.success({
          message: 'Remove category',
          description: 'Successfully',
        });
        fetch(tableData.pagination, tableData.sort);
      });
  };
  const handleTableChange = (pagination: Pagination) => {
    fetch(pagination, tableData.sort);
  };
  const openCategoryModal = () => {
    setIsCreateOpen(true);
  };
  const hideCategoryModal = () => {
    setIsCreateOpen(false);
  };

  const onSorterPress = (sortBy: PaginationSortBy) => {
    if (tableData.sort.sortBy === sortBy) {
      fetch(
        {
          ...initialPagination,
          pageSize: tableData.pagination.pageSize,
        },
        { sortBy: sortBy, order: tableData.sort.order === 'asc' ? 'desc' : 'asc' },
      );
    } else {
      fetch(
        {
          ...initialPagination,
          pageSize: tableData.pagination.pageSize,
        },
        { sortBy: sortBy, order: 'asc' },
      );
    }
  };

  const renderSorterHeader = (title: string, key: PaginationSortBy) => {
    let _tooltip = t('dataDisplay.pagination.clickToSortAsc');
    let _orderAsc: boolean | undefined = undefined;
    if (tableData.sort.sortBy === key) {
      if (tableData.sort.order === 'asc') {
        _tooltip = t('dataDisplay.pagination.clickToSortDesc');
        _orderAsc = true;
      } else {
        _tooltip = t('dataDisplay.pagination.clickToSortAsc');
        _orderAsc = false;
      }
    }
    return (
      <Tooltip placement="topLeft" title={_tooltip}>
        <a onClick={() => onSorterPress(key)}>
          {title}{' '}
          {_orderAsc !== undefined ? (
            _orderAsc ? (
              <CaretUpFilled size={14} />
            ) : (
              <CaretDownFilled size={14} />
            )
          ) : (
            <SwapOutlined size={14} />
          )}
        </a>
      </Tooltip>
    );
  };

  const columns: ColumnsType<CategoryTableRow> = [
    {
      title: () => renderSorterHeader('Title', 'title'),
      dataIndex: 'title',
      key: 'title',
      render: (_, record) => (
        <Space size="middle">
          <Avatar shape="square" src={record.photo && record.photo !== '' ? record.photo : noImage} />
          {record.title}
        </Space>
      ),
    },
    {
      title: 'Language',
      dataIndex: 'lang',
      key: 'lang',
      render: (_, record) => getLanguageByCode(record.lang)?.name,
    },
    {
      title: () => renderSorterHeader('Order number', 'orderNumber'),
      dataIndex: 'orderNo',
      key: 'orderNo',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space>
          <Button type="link" onClick={() => handleEdit(record)}>
            Edit
          </Button>
          <Popconfirm
            title="Are you sure to delete this category?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => handleDelete(record.id)}
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Card
        padding="0 1.875rem"
        title="Category list"
        extra={
          <Button type="primary" icon={<PlusOutlined />} onClick={openCategoryModal}>
            Create new
          </Button>
        }
      >
        <Table
          loading={tableData.loading}
          columns={columns}
          dataSource={tableData.data}
          pagination={{ ...tableData.pagination, style: { alignItems: 'center' } }}
          onChange={handleTableChange}
          scroll={{ x: 800 }}
          bordered
          rowKey={(record) => record.id}
        />
      </Card>
      <BaseButtonsForm.Provider
        onFormFinish={(name, { values, forms }) => {
          if (name === 'updateCategoryForm') {
            setIsEditOpen(false);
          } else if (name === 'addCategoryForm') {
            hideCategoryModal();
          }
        }}
      >
        <AddCategoryFormModal
          open={isCreateOpen}
          onCancel={hideCategoryModal}
          onFinish={() => setTimeout(() => fetch(tableData.pagination, tableData.sort), 250)}
        />
        {itemSelected && (
          <UpdateCategoryFormModal
            open={isEditOpen}
            onCancel={() => setItemSelected(null)}
            onFinish={() =>
              setTimeout(() => {
                setItemSelected(null);
                fetch(tableData.pagination, tableData.sort);
              }, 250)
            }
            data={itemSelected}
          />
        )}
      </BaseButtonsForm.Provider>
    </>
  );
};

export default CategoryPage;
