import React, { useEffect, useState, useCallback } from 'react';
import { Col, Row, Space } from 'antd';
import { Table } from 'components/common/Table/Table';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { useMounted } from '@app/hooks/useMounted';
import { Card } from '@app/components/common/Card/Card';
import { Pagination } from '@app/api/category.api';
import { Button } from '@app/components/common/buttons/Button/Button';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { notificationController } from '@app/controllers/notificationController';
import { formatDate } from '@app/utils/utils';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Input } from '@app/components/common/inputs/Input/Input';
import { GeneratorData, GeneratorTableRow, getGeneratorList, getLinkDownloadCoupons } from '@app/api/coupon.api';
import { doGenerator } from '@app/store/slices/couponSlice';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { ViewCouponsModal } from '@app/components/coupon/ViewCouponsModal';

const initialPagination: Pagination = {
  current: 1,
  pageSize: 10,
  total: 0,
  defaultPageSize: 10,
  showSizeChanger: true,
  pageSizeOptions: ['10', '50', '100'],
};

const CouponPage: React.FC = () => {
  const [tableData, setTableData] = useState<{
    data: GeneratorTableRow[];
    pagination: Pagination;
    loading: boolean;
  }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });
  const dispatch = useAppDispatch();
  const [form] = BaseForm.useForm();
  const { t } = useTranslation();
  const { isMounted } = useMounted();
  const [loading, setLoading] = useState<boolean>(false);
  const [isViewOpen, setIsViewOpen] = useState<boolean>(false);
  const [itemSelected, setItemSelected] = useState<GeneratorTableRow | null>(null);

  const fetch = useCallback(
    (pagination: Pagination) => {
      setTableData((tableData) => ({ ...tableData }));
      getGeneratorList({
        page: pagination.current,
        limit: pagination.pageSize,
      }).then((res) => {
        setTableData({
          data: res.data.data,
          pagination: { ...pagination, total: res.data.total },
          loading: false,
        });
      });
    },
    [isMounted],
  );

  useEffect(() => {
    fetch(initialPagination);
  }, [fetch]);

  const handleTableChange = (pagination: Pagination) => {
    fetch(pagination);
  };

  const handleSubmit = (values: GeneratorData) => {
    setLoading(true);
    const count = parseInt(String(values.count));
    dispatch(doGenerator({ ...values, count }))
      .unwrap()
      .then(() => {
        setLoading(false);
        notificationController.success({
          message: 'Generate coupons successfully!',
          description: 'Successfully',
        });

        setTimeout(() => {
          fetch(initialPagination);
        }, 250);
        form.resetFields();
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleViewCoupons = (record: GeneratorTableRow) => {
    setItemSelected(record);
    setTimeout(() => {
      setIsViewOpen(true);
    }, 250);
  };

  const columns: ColumnsType<GeneratorTableRow> = [
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record) => formatDate(record.createdAt),
    },
    {
      title: 'Prefix',
      dataIndex: 'prefix',
      key: 'prefix',
    },
    {
      title: 'Count',
      dataIndex: 'count',
      key: 'count',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space>
          <Button type="link" href={getLinkDownloadCoupons(record.id)}>
            Download
          </Button>
          <Button style={{ marginTop: '-12px' }} type="link" onClick={() => handleViewCoupons(record)}>
            View
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Card padding="1.875rem" title="Generating VIP Coupon Code">
        <BaseForm form={form} layout="vertical" name="addVideoForm" onFinish={handleSubmit}>
          <Row align="middle" justify="start" gutter={[10, 10]}>
            <Col span={4}>
              <BaseForm.Item
                name="prefix"
                label="Prefix"
                rules={[{ required: true, message: t('common.requiredField') }]}
              >
                <Input disabled={loading} />
              </BaseForm.Item>
            </Col>
            <Col span={4}>
              <BaseForm.Item
                name="count"
                label="Total Count"
                rules={[{ required: true, message: t('common.requiredField') }]}
              >
                <Input disabled={loading} type="number" />
              </BaseForm.Item>
            </Col>
            <Col span={2}>
              <Button disabled={loading} style={{ marginTop: '15px' }} htmlType="submit">
                Generate
              </Button>
            </Col>
          </Row>
        </BaseForm>
        <Table
          loading={tableData.loading}
          columns={columns}
          dataSource={tableData.data}
          pagination={{ ...tableData.pagination, style: { alignItems: 'center' } }}
          onChange={handleTableChange}
          scroll={{ x: 800 }}
          bordered
          rowKey={(record) => record.id}
        />
      </Card>
      <BaseButtonsForm.Provider>
        {itemSelected && (
          <ViewCouponsModal
            open={isViewOpen}
            onCancel={() => {
              setIsViewOpen(false);
              setItemSelected(null);
            }}
            data={itemSelected}
          />
        )}
      </BaseButtonsForm.Provider>
    </>
  );
};

export default CouponPage;
