import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { useResetFormOnCloseModal } from '@app/components/forms/ControlForm/useResetFormOnCloseModal';
// import { useAppDispatch } from '@app/hooks/reduxHooks';
import { getCategoryList } from '@app/api/category.api';
import { CategoryModel } from '@app/domain/CategoryModel';
import { Pagination, UserTableRow, getVideosWatched } from '@app/api/users.api';
import { Avatar } from '@app/components/common/Avatar/Avatar';
import defaultAvatar from 'assets/images/default-avatar.jpg';
import { Table } from '@app/components/common/Table/Table';
import { ColumnsType } from 'antd/lib/table';
import { VideoTableRow } from '@app/api/video.api';
import { useMounted } from '@app/hooks/useMounted';

interface AddUserFormModalProps {
  data: UserTableRow;
  open: boolean;
  onCancel: () => void;
}

interface OptionValue {
  key: string;
  value: string;
}
const initialPagination: Pagination = {
  current: 1,
  pageSize: 5,
  total: 0,
};

export const UserDetailModal: React.FC<AddUserFormModalProps> = ({ open, onCancel, data }) => {
  const [form] = BaseForm.useForm();
  const { t } = useTranslation();
  const { isMounted } = useMounted();
  const [isLoading, setLoading] = useState(false);
  // const [categories, setCategories] = useState<OptionValue[]>([]);
  // const [cateSelected, setCateSelected] = useState<OptionValue>();
  // const [progress, setProgress] = useState(0);
  const [tableData, setTableData] = useState<{ data: UserTableRow[]; pagination: Pagination; loading: boolean }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });

  useResetFormOnCloseModal({
    form,
    open,
  });

  const fetch = useCallback((pagination: Pagination) => {
    setTableData((tableData) => ({ ...tableData, loading: true, data: [] }));
    getVideosWatched(data.id).then((res) => {
      setTableData({
        data: res.data.data,
        pagination: { ...pagination, total: res.data.total },
        loading: false,
      });
    });
  }, []);

  // const onOk = () => {
  //   form.submit();
  // };

  useEffect(() => {
    // getCategoryList({ page: 1, limit: 50 }).then((res) => {
    //   setCategories(res.data.data.map((v: CategoryModel) => ({ key: v.id, value: v.name })));
    // });
    fetch(initialPagination);
  }, [data]);

  const columns: ColumnsType<VideoTableRow> = [
    {
      title: 'name',
      dataIndex: 'title',
      key: 'title',
    },
  ];

  const handleTableChange = (pagination: Pagination) => {
    fetch(pagination);
  };

  return (
    <Modal
      open={open}
      title="Detail user"
      onOk={onCancel}
      onCancel={onCancel}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <BaseForm layout="vertical">
        <BaseForm.Item label="Name">
          <Avatar shape="circle" src={data.photo ? data.photo.path : defaultAvatar} />
          {data.firstName} {data.lastName}
        </BaseForm.Item>
        <BaseForm.Item label={t('common.name')}>{data.email}</BaseForm.Item>
        <BaseForm.Item label="Role">{data.role?.name}</BaseForm.Item>
        <BaseForm.Item label="Status">{data.status?.name}</BaseForm.Item>
        <BaseForm.Item label="Video watched">
          <Table
            columns={columns}
            dataSource={tableData.data}
            loading={tableData.loading}
            pagination={tableData.pagination}
            onChange={handleTableChange}
            scroll={{ x: 400 }}
            bordered
          />
        </BaseForm.Item>
      </BaseForm>
    </Modal>
  );
};
