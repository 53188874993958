import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Input } from '@app/components/common/inputs/Input/Input';
import { notificationController } from '@app/controllers/notificationController';
import { useResetFormOnCloseModal } from '@app/components/forms/ControlForm/useResetFormOnCloseModal';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { UpdateUserData, UserTableRow } from '@app/api/users.api';
import { doUpdateUser } from '@app/store/slices/userSlice';
import { Select } from '@app/components/common/selects/Select/Select';

interface UpdateUserFormModalProps {
  data: UserTableRow;
  open: boolean;
  onCancel: () => void;
  onFinish: () => void;
}

interface OptionValue {
  key: string;
  value: string;
}

const initialInfoValues = {
  id: -1,
};

const roles: OptionValue[] = [
  { key: '1', value: 'admin' },
  { key: '2', value: 'user' },
];

export const UpdateUserFormModal: React.FC<UpdateUserFormModalProps> = ({ open, onCancel, onFinish, data }) => {
  const [form] = BaseForm.useForm();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);
  const [status, setStatus] = useState<boolean>(false);
  const [roleSelected, setRoleSelected] = useState<OptionValue>();

  useResetFormOnCloseModal({
    form,
    open,
  });

  const onOk = () => {
    form.submit();
  };

  useEffect(() => {
    setStatus(data.status?.id === 1 ? true : false);
  }, [data]);

  const userFormValues = useMemo(
    () =>
      data
        ? {
            ...data,
            role: { key: data.role?.id, value: data.role?.name },
            status: { key: data.status?.id, value: data.status?.name },
          }
        : initialInfoValues,
    [data],
  );

  const handleSubmit = (values: UpdateUserData) => {
    setLoading(true);
    const _roleId = roleSelected ? roleSelected.key : data.role?.id ?? 2;
    dispatch(doUpdateUser({ ...values, id: data.id, role: { id: +_roleId } }))
      .unwrap()
      .then(() => {
        setLoading(false);
        notificationController.success({
          message: 'Update user',
          description: 'Successfully',
        });
        onFinish();
      })
      .catch((err) => {
        // notificationController.error({ message: err.message });
        setLoading(false);
      });
  };

  return (
    <Modal
      title="Update user"
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      confirmLoading={isLoading}
      maskClosable={false}
    >
      <BaseForm
        initialValues={userFormValues}
        form={form}
        layout="vertical"
        name="updateUserForm"
        onFinish={handleSubmit}
      >
        <BaseForm.Item
          name="firstName"
          label="First name"
          rules={[{ required: false, message: t('common.requiredField') }]}
        >
          <Input />
        </BaseForm.Item>
        <BaseForm.Item
          name="lastName"
          label="Last name"
          rules={[{ required: false, message: t('common.requiredField') }]}
        >
          <Input />
        </BaseForm.Item>
        <BaseForm.Item name="role" label="Role" rules={[{ required: true, message: t('common.requiredField') }]}>
          <Select
            options={roles}
            onSelect={(v, _record) => {
              setRoleSelected(_record as OptionValue);
            }}
          />
        </BaseForm.Item>
        {/* <BaseForm.Item name="status" label="Status" rules={[{ required: false, message: t('common.requiredField') }]}>
          <Switch checked={status} onChange={() => setStatus(!status)} />
        </BaseForm.Item> */}
      </BaseForm>
    </Modal>
  );
};
