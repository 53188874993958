import { httpApi } from '@app/api/http.api';
import { FileModel, UserModel } from '@app/domain/UserModel';

export type PaginationSortBy = 'name' | 'email' | 'createdAt';

export type PaginationOrder = 'asc' | 'desc';
export interface Pagination {
  current?: number;
  pageSize?: number;
  total?: number;
  defaultPageSize?: number;
  showSizeChanger?: boolean;
  pageSizeOptions?: string[] | number[];
}

export interface UserListRequest {
  page?: number;
  limit?: number;
  sortBy?: PaginationSortBy;
  order?: PaginationOrder;
}

export interface UserListResponse {
  data: UserModel[];
  hasNextPage: boolean;
  pagination: Pagination;
}

export interface Status {
  id: number;
  name?: string;
}

export interface Role {
  id: number;
  name?: string;
}

export interface UserTableRow {
  id: number;
  email: string;
  provider?: string;
  socialId?: string;
  firstName: string;
  lastName: string;
  createdAt: string;
  // updatedAt: string;
  // deletedAt: string;
  role?: Role;
  status?: Status;
  isActive: boolean;
  photo?: FileModel;
}

export interface UpdateUserData {
  id: number;
  firstName?: string;
  lastName?: string;
  role?: { id: number };
  // status?: { id: number };
}
export interface NewUserData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  role: { id: number };
  status: { id: number };
}

export const getUserList = async (payload: UserListRequest) => {
  return httpApi.get('/users', { params: payload });
};

export const getVideosWatched = async (userId: number) => httpApi.get(`/users/watched/${userId}`);

export const updateUser = async (id: number, payload: UpdateUserData) =>
  httpApi.patch(`/users/${id}`, { ...payload }).then(({ data }) => data);

export const newUser = async (payload: NewUserData) => httpApi.post(`/users`, { ...payload }).then(({ data }) => data);

export const disableUser = async (id: number) => httpApi.patch(`/users/disable/${id}`);

export const activeUser = async (id: number) => httpApi.patch(`/users/active/${id}`);

export const deleteUser = async (id: number) => httpApi.delete(`/users/${id}`);

export const bulkDeleteUser = async (ids: string) => httpApi.delete(`/users/delete-multi/${ids}`);
