import { httpApi } from '@app/api/http.api';
import { UserModel } from '@app/domain/UserModel';
import { CateModel, VideoModel } from '@app/domain/VideoModel';

export type PaginationSortBy = 'author' | 'title' | 'category' | 'view' | 'lastUpdate';

export type PaginationOrder = 'asc' | 'desc';

export interface Pagination {
  current?: number;
  pageSize?: number;
  total?: number;
  defaultPageSize?: number;
  showSizeChanger?: boolean;
  pageSizeOptions?: string[] | number[];
}

export interface VideoListRequest {
  page?: number;
  limit?: number;
  sortBy?: PaginationSortBy;
  order?: PaginationOrder;
  categoryId?: number;
}

export interface VideoListResponse {
  data: VideoModel[];
  hasNextPage: boolean;
  pagination: Pagination;
}

export interface VideoTableRow {
  id: number;
  title: string;
  name: string;
  thumbnail: string;
  // description_short: string;
  description: string;
  likedCount: number;
  viewedCount: number;
  watchLaterCount: string;
  category: CateModel;
  author: UserModel;
  status: string;
  photo: string;
  uploading: boolean;
  vimeoId: string;
  categories: CateModel[];
}

export interface VideoSelectTableRow {
  id: number;
  title: string;
}

export interface VimeoSelectTableRow {
  name: string;
  vimeoId: string;
}

export interface VimeoVideo {
  name: string;
  uri: string;
}

export interface NewVideoData {
  title: string;
  lang: string;
  // description_short?: string;
  description?: string;
  // categoryId?: number;
  category?: CateModel;
  categories?: CateModel[];
  thumbnail?: string;
  files: File[];
  vimeoId: string;
}

export interface UpdateVideoData {
  id: number;
  title: string;
  // description_short?: string;
  description?: string;
  // categoryId?: number;
  category?: CateModel;
  categories?: CateModel[];
  thumbnail?: string;
  files: File[];
  vimeoId: string;
}

export interface VimeoListRequest {
  page?: number;
  limit?: number;
}

export const getVideoList = async (payload: VideoListRequest) => {
  return httpApi.get('/video', { params: payload });
};

export const getVideoFirebaseList = async (cateId: number) => {
  return httpApi.get('/video/firebase/', { params: { cateId } });
};

export const getVideosByCate = async (cateId: number, payload: VideoListRequest) => {
  return httpApi.get(`/video/category/${cateId}`, { params: payload });
};

export const addNewVideo = (newVideoData: NewVideoData): Promise<undefined> =>
  httpApi.post<undefined>('/video', { ...newVideoData }).then(({ data }) => data);

export const updateVideo = (id: number, updateVideoData: UpdateVideoData): Promise<undefined> =>
  httpApi.patch<undefined>(`/video/${id}`, { ...updateVideoData }).then(({ data }) => data);

export const deleteVideo = (id: number): Promise<undefined> =>
  httpApi.delete<undefined>(`/video/${id}`).then(({ data }) => data);

export const getVimeoVideoList = async (payload: VimeoListRequest) => {
  return httpApi.get('/video/vimeo', { params: payload });
};
