import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { notificationController } from '@app/controllers/notificationController';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import * as S from './NewPasswordForm.styles';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { resetPassword, verifyFirebasePasswordResetCode } from '@app/services/firebase.service';
interface NewPasswordFormModalProps {
  oob: string | null;
}
interface NewPasswordFormData {
  email: string;
  password: string;
  confirmPassword: string;
  oob: string;
}

const initStates = {
  password: '',
  confirmPassword: '',
  oob: '',
};

export const NewPasswordForm: React.FC<NewPasswordFormModalProps> = ({ oob }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [form] = BaseForm.useForm();

  const [isLoading, setLoading] = useState(true);
  const [result, setResult] = useState('');

  useEffect(() => {
    if (oob) {
      init(oob);
    } else {
      setResult(t('newPassword.noOOBCode'));
    }
  }, []);

  const init = async (oob: string) => {
    const res = await verifyFirebasePasswordResetCode(oob);
    if (res.status) {
      form.setFieldsValue({ email: res.data });
    } else {
      setResult(res.data);
    }
    setLoading(false);
  };

  const handleSubmit = async (values: NewPasswordFormData) => {
    setLoading(true);
    const res = await resetPassword(values.password, values.oob);
    if (res) {
      setResult(`${t('newPassword.successMessage')}. ${t('newPassword.successDescription')}`);
    }
    setLoading(false);
  };

  return (
    <Auth.FormWrapper>
      <BaseForm form={form} layout="vertical" onFinish={handleSubmit} initialValues={{ ...initStates, oob }}>
        {/* <Auth.BackWrapper onClick={() => navigate(-1)}>
          <Auth.BackIcon />
          {t('common.back')}
        </Auth.BackWrapper> */}
        {result && result.length > 0 ? (
          <>
            <Auth.FormTitle>{t('common.newPassword')}</Auth.FormTitle>
            <S.Description>{result}</S.Description>
          </>
        ) : (
          <>
            <Auth.FormTitle>{t('newPassword.title')}</Auth.FormTitle>
            <S.Description>{t('newPassword.description')}</S.Description>
            <Auth.FormItem name="email" label="Email" rules={[{ required: true, message: t('common.requiredField') }]}>
              <Auth.FormInput placeholder="email" disabled />
            </Auth.FormItem>
            <Auth.FormItem
              name="password"
              label={t('common.password')}
              rules={[
                {
                  pattern: new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\w~`@$!%*#?&^\(\)\-+=|\\:;"'<>,.\{\}\[\]]{7,}$/),
                  message: t('common.passwordInvalid'),
                },
                { required: true, message: t('common.requiredField') },
              ]}
            >
              <Auth.FormInputPassword placeholder={t('common.password')} />
            </Auth.FormItem>
            <Auth.FormItem
              name="confirmPassword"
              label={t('common.confirmPassword')}
              dependencies={['password']}
              rules={[
                { required: true, message: t('common.requiredField') },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(t('common.confirmPasswordError')));
                  },
                }),
              ]}
              hasFeedback
            >
              <Auth.FormInputPassword placeholder={t('common.confirmPassword')} />
            </Auth.FormItem>
            <Auth.FormItem name="oob" rules={[{ required: true, message: t('common.requiredField') }]} hidden>
              <Auth.FormInput placeholder="oob code" hidden />
            </Auth.FormItem>
            <BaseForm.Item noStyle>
              <S.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
                {t('common.resetPassword')}
              </S.SubmitButton>
            </BaseForm.Item>
          </>
        )}
      </BaseForm>
    </Auth.FormWrapper>
  );
};
