import { httpApi } from '@app/api/http.api';
import { CommentModel } from '@app/domain/CommentModel';
import { UserModel } from '@app/domain/UserModel';
import { VideoModel } from '@app/domain/VideoModel';

export type PaginationSortBy = 'author' | 'authorReply' | 'video' | 'createdAt' | 'updateAt';

export type PaginationOrder = 'asc' | 'desc';

export interface Pagination {
  current?: number;
  pageSize?: number;
  total?: number;
  defaultPageSize?: number;
  showSizeChanger?: boolean;
  pageSizeOptions?: string[] | number[];
}

export interface CommentListRequest {
  page?: number;
  limit?: number;
  sortBy?: PaginationSortBy;
  order?: PaginationOrder;
}

export interface CommentListResponse {
  data: CommentModel[];
  hasNextPage: boolean;
  pagination: Pagination;
}

export interface CommentTableRow {
  id: number;
  videoTitle: string;
  content?: string;
  likedCount: number;
  createdAt: string;
  updatedAt?: string;
  deletedAt?: string;
  replyId?: number;
  replyContent?: string;
  replyLikedCount?: number;
  authorReplyFirstName?: string;
  authorReplyLastName?: string;
  authorReplyPhoto?: string;
  replyAuthorId?: number;
  replyCreatedAt: string;
  replyUpdatedAt?: string;
  replyDeletedAt?: string;
  videoId: number;
  authorId: number;
  authorFirstName?: string;
  authorLastName?: string;
  authorPhoto?: string;
}

export const getCommentList = async (payload: CommentListRequest) => {
  return httpApi.get('/comment', { params: payload });
};

export const getCommentLv2List = async (id: number | undefined, payload: CommentListRequest) => {
  return httpApi.get(`/comment/lv2/${id}`, { params: payload });
};

export const deleteComment = (id: number): Promise<undefined> =>
  httpApi.delete<undefined>(`/comment/${id}`).then(({ data }) => data);

export const deleteCommentLv2 = (id: number): Promise<undefined> =>
  httpApi.delete<undefined>(`/comment/lv2/${id}`).then(({ data }) => data);

export const bulkDeleteComment = async (ids: string) => httpApi.delete(`/comment/delete-multi/${ids}`);

export const bulkDeleteCommentLv2 = async (ids: string) => httpApi.delete(`/comment/lv2/delete-multi/${ids}`);
