import { httpApi } from '@app/api/http.api';
import { FileModel } from '@app/domain/FileModel';
import { UserModel } from '@app/domain/UserModel';

export interface AuthData {
  email: string;
  password: string;
}

export interface SignUpRequest {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export interface UpdateMeRequest {
  firstName?: string;
  lastName?: string;
  photo?: FileModel;
}
export interface UpdateMeResponse {
  firstName?: string;
  lastName?: string;
  photo?: FileModel;
}

export interface ResetPasswordRequest {
  email: string;
}

export interface SecurityCodePayload {
  code: string;
}

export interface NewPasswordData {
  password: string;
  hash: string;
}
export interface ChangePasswordData {
  // oldPassword: string;
  password: string;
}

export interface LoginRequest {
  email: string;
  idToken: string;
}

export interface LoginResponse {
  token: string;
  user: UserModel;
}

export interface ResendVerificationRequest {
  email: string;
  idToken?: string;
}

export const login = async (loginPayload: LoginRequest): Promise<LoginResponse> =>
  httpApi.post<LoginResponse>('auth/admin/email/login', { ...loginPayload }).then(({ data }) => data);

export const me = async (): Promise<LoginResponse> => {
  return httpApi.get<LoginResponse>('auth/me').then(({ data }) => data);
};

export const signUp = (signUpData: SignUpRequest): Promise<undefined> =>
  httpApi.post<undefined>('signUp', { ...signUpData }).then(({ data }) => data);

export const resetPassword = (resetPasswordPayload: ResetPasswordRequest): Promise<undefined> =>
  httpApi.post<undefined>('auth/forgot/password', { ...resetPasswordPayload }).then(({ data }) => data);

export const verifySecurityCode = (securityCodePayload: SecurityCodePayload): Promise<undefined> =>
  httpApi.post<undefined>('verifySecurityCode', { ...securityCodePayload }).then(({ data }) => data);

export const setNewPassword = (newPasswordData: NewPasswordData): Promise<undefined> =>
  httpApi.post<undefined>('auth/reset/password', { ...newPasswordData }).then(({ data }) => data);

export const changePassword = (data: ChangePasswordData): Promise<undefined> =>
  httpApi.patch<undefined>('auth/me', { ...data }).then(({ data }) => data);

export const updateMe = (data: UpdateMeRequest): Promise<UserModel> =>
  httpApi.patch<UserModel>('auth/me', { ...data }).then(({ data }) => data);

export const resendVerificationEmail = (payload: ResendVerificationRequest): Promise<undefined> =>
  httpApi.post<undefined>('auth/email/resend-code', { ...payload }).then(({ data }) => data);
