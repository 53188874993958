import { httpApi } from '@app/api/http.api';
import { CategoryModel, SelectedVideosModel } from '@app/domain/CategoryModel';

export type PaginationSortBy = 'title' | 'orderNumber';

export type PaginationOrder = 'asc' | 'desc';
export interface Pagination {
  current?: number;
  pageSize?: number;
  total?: number;
  defaultPageSize?: number;
  showSizeChanger?: boolean;
  pageSizeOptions?: string[] | number[];
}

export interface CategoryListRequest {
  page?: number;
  limit?: number;
  sortBy?: PaginationSortBy;
  order?: PaginationOrder;
}

export interface CategoryListResponse {
  data: CategoryModel[];
  hasNextPage: boolean;
  pagination: Pagination;
}

export interface CategoryTableRow {
  id: number;
  lang: string;
  title: string;
  name: string;
  orderNo: number;
  photo: string;
}

export interface NewCategoryData {
  title: string;
  name: string;
  videos: SelectedVideosModel[];
}

export interface UpdateCategoryData {
  id: number;
  title: string;
  name?: string;
  photo: string;
  orderNo?: number;
  files: File[];
  videos: SelectedVideosModel[];
}

export const getCategoryList = async (payload: CategoryListRequest) => {
  return httpApi.get('/category', { params: payload });
};

export const getCategoryFirebaseList = async () => {
  return httpApi.get('/category/firebase');
};

export const addNewCategory = (newCategoryData: NewCategoryData): Promise<undefined> =>
  httpApi.post<undefined>('/category', { ...newCategoryData }).then(({ data }) => data);

export const updateCategory = (id: number, updateCategoryData: UpdateCategoryData): Promise<undefined> =>
  httpApi.patch<undefined>(`/category/${id}`, { ...updateCategoryData }).then(({ data }) => data);

export const deleteCategory = (id: number): Promise<undefined> =>
  httpApi.delete<undefined>(`/category/${id}`).then(({ data }) => data);
