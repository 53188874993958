import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Table } from 'components/common/Table/Table';
import { ColumnsType } from 'antd/es/table';
import { Card } from '@app/components/common/Card/Card';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { useMounted } from '@app/hooks/useMounted';
import { getStats } from '@app/api/general.api';

export interface StatsRow {
  name: string;
  type: string;
  '1mo': number;
  '1y': number;
  '24h': number;
  '7d': number;
}

const DashboardPage: React.FC = () => {
  const [isLoading, setLoading] = useState(false);
  const [tableData, setTableData] = useState<StatsRow[]>([]);
  const { isMounted } = useMounted();

  const fetch = useCallback(async () => {
    setLoading(true);
    return await getStats().then((res) => {
      const data: StatsRow[] = res.data;
      setTableData(data);
      setLoading(false);
    });
  }, [isMounted]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const columns: ColumnsType<StatsRow> = useMemo(
    () => [
      {
        title: ' ',
        dataIndex: 'name',
        key: 'Name',
      },
      {
        title: '24h',
        dataIndex: '24h',
        key: '24h',
      },
      {
        title: '7d',
        dataIndex: '7d',
        key: '7d',
        render: (_, record) => <span>{record['7d'] + record['24h']}</span>,
      },
      {
        title: 'Month',
        dataIndex: '1mo',
        key: '1mo',
        render: (_, record) => <span>{record['1mo'] + record['24h']}</span>,
      },
      {
        title: 'Year',
        dataIndex: '1y',
        key: '1y',
        render: (_, record) => <span>{record['1y'] + record['24h']}</span>,
      },
    ],
    [],
  );

  return (
    <>
      <Card padding="1.875rem">
        <Table
          columns={columns}
          dataSource={tableData}
          loading={isLoading}
          pagination={false}
          bordered
          rowKey={(record) => record.docId}
        />
      </Card>
    </>
  );
};

export default DashboardPage;
