import axios, { AxiosResponse } from 'axios';
import { ApiError } from '@app/api/ApiError';
import { readToken } from '@app/services/localStorage.service';
import { notificationController } from '@app/controllers/notificationController';
import { doLogout } from '@app/store/slices/authSlice';

let store: any;

export const injectStore = (_store: any) => {
  store = _store;
};

export const httpApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

httpApi.interceptors.request.use((config) => {
  config.headers = { ...config.headers, Authorization: `Bearer ${readToken()}` };

  return config;
});

const onResponseSuccess = (response: AxiosResponse) => {
  return response;
};

const onResponseError = (error: any) => {
  if (error && error.response && error.response.status === 401) {
    console.log('[http] 401:', error.message);
    store
      ?.dispatch(doLogout())
      .unwrap()
      .then(() => {
        window.location.href = '/auth/login';
      })
      .catch((err: any) => {
        notificationController.error({ message: err.message });
      });
    throw new ApiError<ApiErrorData>(error.message, error.response?.data);
  }
  const errObj = error.response.data?.errors ? error.response.data?.errors : { err: error.message };
  const status = errObj['status'];
  const errMsg = Object.keys(errObj)
    .filter((value) => value !== 'status')
    .map((k) => errObj[k])
    .join(', ');
  notificationController.error({ message: errMsg });

  throw new ApiError<ApiErrorData>(errMsg || error.message, status ?? error.response?.data);
};

httpApi.interceptors.response.use(onResponseSuccess, onResponseError);

export interface ApiErrorData {
  message: string;
}
