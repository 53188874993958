import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  CommentListRequest,
  bulkDeleteComment,
  bulkDeleteCommentLv2,
  deleteComment,
  deleteCommentLv2,
  getCommentList,
} from '@app/api/comment.api';
import { CommentModel } from '@app/domain/CommentModel';

export interface CommentState {
  comments: CommentModel[] | null;
}

const initialState: CommentState = {
  comments: [],
};

export const getComments = createAsyncThunk('comment/list', async (payload: CommentListRequest, { dispatch }) =>
  getCommentList(payload).then((res) => {
    dispatch(res.data.data);
    return res.data.data;
  }),
);

export const doDeleteComment = createAsyncThunk('comment/doDeleteComment', async (id: number) => deleteComment(id));

export const doDeleteCommentLv2 = createAsyncThunk('comment/doDeleteCommentLv2', async (id: number) =>
  deleteCommentLv2(id),
);

export const doBulkDeleteComment = createAsyncThunk('user/doBulkDeleteComment', async (ids: number[]) =>
  bulkDeleteComment(ids.join(',')),
);

export const doBulkDeleteCommentLv2 = createAsyncThunk('user/doBulkDeleteCommentLv2', async (ids: number[]) =>
  bulkDeleteCommentLv2(ids.join(',')),
);

export const commentSlice = createSlice({
  name: 'comment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getComments.fulfilled, (state, action) => {
      state.comments = action.payload;
    });
  },
});

export default commentSlice.reducer;
