import React from 'react';
import { Col, Row } from 'antd';
import { ProfileDropdown } from '../components/profileDropdown/ProfileDropdown/ProfileDropdown';
import { SettingsDropdown } from '../components/settingsDropdown/SettingsDropdown';
import { HeaderFullscreen } from '../components/HeaderFullscreen/HeaderFullscreen';
import * as S from '../Header.styles';

interface DesktopHeaderProps {
  isTwoColumnsLayout: boolean;
}

export const DesktopHeader: React.FC<DesktopHeaderProps> = ({ isTwoColumnsLayout }) => {
  // const leftSide = isTwoColumnsLayout ? (
  //   <S.SearchColumn xl={16} xxl={17}>
  //     <Row justify="space-between">
  //       <Col xl={15} xxl={12}>
  //         <HeaderSearch />
  //       </Col>
  //       <Col>
  //         <S.GHButton />
  //       </Col>
  //     </Row>
  //   </S.SearchColumn>
  // ) : (
  //   <>
  //     <Col lg={10} xxl={8}>
  //       <HeaderSearch />
  //     </Col>
  //     <Col>
  //       <S.GHButton />
  //     </Col>
  //   </>
  // );

  return (
    <Row justify="space-between" align="middle">
      <>
        <Col lg={10} xxl={8}></Col>
      </>

      <S.ProfileColumn xl={8} xxl={7} $isTwoColumnsLayout={isTwoColumnsLayout}>
        <Row align="middle" justify="end" gutter={[10, 10]}>
          <Col>
            <Row gutter={[{ xxl: 10 }, { xxl: 10 }]}>
              <Col>
                <HeaderFullscreen />
              </Col>

              {/* <Col>
                <NotificationsDropdown />
              </Col> */}

              <Col>
                <SettingsDropdown />
              </Col>
            </Row>
          </Col>

          <Col>
            <ProfileDropdown />
          </Col>
        </Row>
      </S.ProfileColumn>
    </Row>
  );
};
