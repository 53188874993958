import userReducer from '@app/store/slices/userSlice';
import authReducer from '@app/store/slices/authSlice';
import nightModeReducer from '@app/store/slices/nightModeSlice';
import themeReducer from '@app/store/slices/themeSlice';
import pwaReducer from '@app/store/slices/pwaSlice';
import videoReducer from '@app/store/slices/videoSlice';
import categoryReducer from '@app/store/slices/categorySlice';
import commentReducer from '@app/store/slices/commentSlice';
import couponReducer from '@app/store/slices/couponSlice';

export default {
  user: userReducer,
  auth: authReducer,
  nightMode: nightModeReducer,
  theme: themeReducer,
  pwa: pwaReducer,
  category: categoryReducer,
  video: videoReducer,
  comment: commentReducer,
  coupon: couponReducer,
};
