import React, { useEffect, useState, useCallback } from 'react';
import { Image, Space, TablePaginationConfig, Tooltip } from 'antd';
import { Table } from 'components/common/Table/Table';
import { ColumnsType } from 'antd/es/table';
import { useMounted } from '@app/hooks/useMounted';
import { Select } from '@app/components/common/selects/Select/Select';
import { getCategoryList } from '@app/api/category.api';
import { CategoryModel } from '@app/domain/CategoryModel';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { CaretDownFilled, CaretUpFilled, PlusOutlined, SwapOutlined } from '@ant-design/icons';
import { AddVideoFormModal } from '@app/components/video/NewVideoForm/NewVideoForm';
import { Button } from '@app/components/common/buttons/Button/Button';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { UpdateVideoFormModal } from '@app/components/video/UpdateVideoForm/UpdateVideoForm';
import { Popconfirm } from '@app/components/common/Popconfirm/Popconfirm';
import { doDeleteVideo } from '@app/store/slices/videoSlice';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { notificationController } from '@app/controllers/notificationController';
import { useTranslation } from 'react-i18next';
import { VideoDetailModal } from '@app/components/video/VideoDetail/VideoDetail';
import { BaseOptionType } from 'antd/lib/select';
import { Pagination, PaginationOrder, PaginationSortBy, VideoTableRow, getVideoList } from '@app/api/video.api';
import { Card } from '@app/components/common/Card/Card';
// import { UploadVideoFormModal } from '@app/components/video/UploadVideoForm/UploadVideoForm';
import { CateModel } from '@app/domain/VideoModel';
import dayjs from 'dayjs';
import { getLanguageByCode } from '@app/utils/utils';

const initialPagination: Pagination = {
  current: 1,
  pageSize: 10,
  total: 0,
  defaultPageSize: 10,
  showSizeChanger: true,
  pageSizeOptions: ['10', '50', '100'],
};
interface OptionValue {
  key: string;
  value: string;
}

const VideoManagerPage: React.FC = () => {
  const { t } = useTranslation();
  const [tableData, setTableData] = useState<{
    data: VideoTableRow[];
    pagination: Pagination;
    loading: boolean;
    sort: {
      sortBy: PaginationSortBy;
      order: PaginationOrder;
    };
    categoryId?: number;
  }>({
    data: [],
    pagination: initialPagination,
    loading: false,
    sort: {
      sortBy: 'view',
      order: 'desc',
    },
  });
  const [isDetailOpen, setIsDetailOpen] = useState<boolean>(false);
  const [isCreateOpen, setIsCreateOpen] = useState<boolean>(false);
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const [category, setCategory] = useState('All');
  const [categories, setCategories] = useState<OptionValue[]>([]);
  const [itemSelected, setItemSelected] = useState<VideoTableRow | null>(null);
  const { isMounted } = useMounted();

  const dispatch = useAppDispatch();

  const fetch = useCallback(
    (pagination: Pagination, sort: { sortBy: PaginationSortBy; order: PaginationOrder }, categoryId?: number) => {
      setTableData((tableData) => ({ ...tableData, sort: sort, categoryId: categoryId, loading: true }));
      getVideoList({
        page: pagination.current,
        limit: pagination.pageSize,
        sortBy: sort.sortBy,
        order: sort.order,
        categoryId: categoryId,
      }).then((res) => {
        setTableData({
          data: res.data.data,
          pagination: { ...pagination, total: res.data.total },
          loading: false,
          sort: sort,
          categoryId: categoryId,
        });
      });
    },
    [isMounted],
  );

  useEffect(() => {
    loadData();
    setCategory('All');
  }, [fetch]);

  const loadData = () => {
    fetch(initialPagination, {
      sortBy: 'view',
      order: 'desc',
    });
    getCategoryList({ page: 1, limit: 100 }).then((res) =>
      setCategories([
        { key: '-1', value: 'All' },
        ...res.data.data.map((v: CategoryModel) => ({ key: v.id, value: v.title })),
      ]),
    );
  };

  const handleTableChange = (pagination: TablePaginationConfig) => {
    fetch(pagination, tableData.sort, tableData.categoryId);
  };

  const handleDelete = (rowId: number) => {
    dispatch(doDeleteVideo(rowId))
      .unwrap()
      .then((res) => {
        notificationController.success({
          message: 'Remove video',
          description: 'Successfully',
        });
        fetch(tableData.pagination, tableData.sort, tableData.categoryId);
      });
  };

  const onSorterPress = (sortBy: PaginationSortBy) => {
    if (tableData.sort.sortBy === sortBy) {
      fetch(
        {
          ...initialPagination,
          pageSize: tableData.pagination.pageSize,
        },
        { sortBy: sortBy, order: tableData.sort.order === 'asc' ? 'desc' : 'asc' },
        tableData.categoryId,
      );
    } else {
      fetch(
        {
          ...initialPagination,
          pageSize: tableData.pagination.pageSize,
        },
        { sortBy: sortBy, order: 'asc' },
        tableData.categoryId,
      );
    }
  };

  const renderSorterHeader = (title: string, key: PaginationSortBy) => {
    let _tooltip = t('dataDisplay.pagination.clickToSortAsc');
    let _orderAsc: boolean | undefined = undefined;
    if (tableData.sort.sortBy === key) {
      if (tableData.sort.order === 'asc') {
        _tooltip = t('dataDisplay.pagination.clickToSortDesc');
        _orderAsc = true;
      } else {
        _tooltip = t('dataDisplay.pagination.clickToSortAsc');
        _orderAsc = false;
      }
    }
    return (
      <Tooltip placement="topLeft" title={_tooltip}>
        <a onClick={() => onSorterPress(key)}>
          {title}{' '}
          {_orderAsc !== undefined ? (
            _orderAsc === true ? (
              <CaretUpFilled size={14} />
            ) : (
              <CaretDownFilled size={14} />
            )
          ) : (
            <SwapOutlined size={14} />
          )}
        </a>
      </Tooltip>
    );
  };

  const columns: ColumnsType<VideoTableRow> = [
    {
      title: 'Thumbnail',
      dataIndex: 'thumbnail',
      key: 'thumbnail',
      width: 190,
      render: (_, record) => (
        <Space size="middle">
          <Image preview={false} width={150} src={record.thumbnail} />
        </Space>
      ),
    },
    {
      title: () => renderSorterHeader('Title', 'title'),
      dataIndex: 'title',
      key: 'title',
      width: 200,
      render: (value, record) => <a onClick={() => handleOpenDetail(record)}>{value}</a>,
    },
    {
      title: 'Language',
      dataIndex: 'lang',
      key: 'lang',
      render: (value) => getLanguageByCode(value)?.name,
    },
    {
      title: () => 'Type',
      dataIndex: 'type',
      key: 'type',
      width: 100,
      render: (value: string, _) => (
        <>
          {value
            .split('-')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')}
        </>
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 200,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => {
        return (
          <Tooltip placement="topLeft" title={text} overlayStyle={{ maxWidth: '500px' }}>
            {text}
          </Tooltip>
        );
      },
    },
    {
      // title: () => renderSorterHeader('Categories', 'category'),
      title: 'Categories',
      dataIndex: 'categories',
      key: 'categories',
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      render: (cates: CateModel[]) => {
        const _text = cates ? cates.map((value) => value.title ?? '').join(', ') : '';
        return (
          <Tooltip placement="topLeft" title={_text} overlayStyle={{ maxWidth: '500px' }}>
            {_text}
          </Tooltip>
        );
      },
    },
    {
      title: 'Vimeo id',
      dataIndex: 'vimeoId',
      key: 'vimeoId',
    },
    {
      title: () => renderSorterHeader('Author', 'author'),
      dataIndex: 'author',
      key: 'author',
      render: (_, record) => (
        <>{record?.author ? `${record.author.firstName} ${record.author.lastName}` : 'Anonymous'}</>
      ),
    },
    {
      title: 'Liked',
      dataIndex: 'likedCount',
      key: 'likedCount',
      width: 100,
    },
    {
      title: () => renderSorterHeader('Viewed', 'view'),
      dataIndex: 'viewedCount',
      key: 'viewedCount',
      width: 110,
      showSorterTooltip: false,
      defaultSortOrder: 'descend',
    },
    {
      title: () => renderSorterHeader('Last update', 'lastUpdate'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (v) => <>{dayjs(v).format('DD/MM/YYYY, HH:mm:ss')}</>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => {
        return (
          <>
            <Button type="link" onClick={() => handleEdit(record)}>
              Edit
            </Button>
            {/* <Button type="link" onClick={() => handleUpload(record)} loading={record.uploading}>
              Upload video
            </Button> */}
            <Popconfirm
              title="Are you sure to delete this video?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => handleDelete(record.id)}
            >
              <Button type="link">Delete</Button>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  const handleSelectCate = (value: any, option: BaseOptionType) => {
    setCategory(value);
    if (value !== 'All') {
      fetch(
        {
          ...initialPagination,
          pageSize: tableData.pagination.pageSize,
        },
        tableData.sort,
        +option.key,
      );
    } else {
      fetch(
        {
          ...initialPagination,
          pageSize: tableData.pagination.pageSize,
        },
        tableData.sort,
      );
    }
  };

  const openNewVideoModal = () => {
    setIsCreateOpen(true);
  };
  const hideNewVideoModal = () => {
    setIsCreateOpen(false);
  };
  const handleEdit = (record: VideoTableRow) => {
    setItemSelected(record);
    setTimeout(() => {
      setIsEditOpen(true);
    }, 250);
  };

  const handleOpenDetail = (record: VideoTableRow) => {
    setItemSelected(record);
    setTimeout(() => {
      setIsDetailOpen(true);
    }, 250);
  };

  return (
    <>
      <Card
        padding="0 1.875rem"
        title="Video list"
        extra={
          <div style={{ display: 'flex' }}>
            <BaseForm.Item style={{ paddingRight: '10px' }}>
              <Select
                value={category}
                style={{ minWidth: 200 }}
                placeholder="Select category"
                width="100%"
                options={categories}
                onChange={handleSelectCate}
              />
            </BaseForm.Item>
            <Button type="primary" icon={<PlusOutlined />} onClick={openNewVideoModal}>
              Create new
            </Button>
          </div>
        }
      >
        <Table
          columns={columns}
          dataSource={tableData.data}
          pagination={{ ...tableData.pagination, style: { alignItems: 'center' } }}
          loading={tableData.loading}
          onChange={handleTableChange}
          scroll={{ x: 1500 }}
          bordered
          rowKey={(record) => record.id}
        />
      </Card>
      <BaseButtonsForm.Provider>
        {itemSelected && (
          <VideoDetailModal
            open={isDetailOpen}
            onCancel={() => {
              setIsDetailOpen(false);
              setItemSelected(null);
            }}
            data={itemSelected}
          />
        )}
        <AddVideoFormModal
          open={isCreateOpen}
          onCancel={hideNewVideoModal}
          onFinish={() => {
            setIsCreateOpen(false);
            setTimeout(() => fetch(tableData.pagination, tableData.sort, tableData.categoryId), 250);
          }}
        />
        {itemSelected && (
          <UpdateVideoFormModal
            open={isEditOpen}
            onCancel={() => {
              setIsEditOpen(false);
              setItemSelected(null);
            }}
            onFinish={() => {
              setIsEditOpen(false);
              setTimeout(() => {
                setItemSelected(null);
                fetch(tableData.pagination, tableData.sort, tableData.categoryId);
              }, 250);
            }}
            data={itemSelected}
          />
        )}
        {/* {itemSelected && (
          <UploadVideoFormModal
            open={isUploadOpen}
            onCancel={() => {
              setIsUploadOpen(false);
              setItemSelected(null);
            }}
            onFinish={() => {
              setIsUploadOpen(false);
              setTimeout(() => {
                setItemSelected(null);
                fetch(tableData.pagination);
              }, 250);
            }}
            data={itemSelected}
          />
        )} */}
      </BaseButtonsForm.Provider>
    </>
  );
};

export default VideoManagerPage;
