import { FirebaseOptions, initializeApp, getApps, FirebaseError } from 'firebase/app';
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  sendEmailVerification,
  sendPasswordResetEmail,
  verifyPasswordResetCode,
  confirmPasswordReset,
  updatePassword,
} from 'firebase/auth';
import i18n from '@app/i18n';
import { notificationController } from '@app/controllers/notificationController';

export const getFirebaseConfig = (): FirebaseOptions => ({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
});

export const firebaseInitialize = () => {
  if (getApps().length <= 0) {
    initializeApp(getFirebaseConfig());
  }
};

if (getApps().length <= 0) {
  firebaseInitialize();
}

const _auth = getAuth();

export const getFirebaseUser = () => {
  return _auth.currentUser;
};

export const signInFirebaseWithPassword = async (email: string, password: string) => {
  return await signInWithEmailAndPassword(_auth, email, password);
};

export const sendFirebaseVerificationEmail = async (): Promise<boolean> => {
  const user = _auth.currentUser;
  if (user) {
    await sendEmailVerification(user);
    notificationController.success({
      message: i18n.t('steps.verification'),
      description: i18n.t('common.verifEmailSent'),
    });
    return true;
  }
  notificationController.error({
    message: i18n.t('steps.verification'),
    description: i18n.t('common.resendVerifEmailFail'),
  });
  return false;
};

export const sendFirebaseForgotPassword = async (email: string): Promise<void> => {
  return await sendPasswordResetEmail(_auth, email);
};

export const firebaseSignOut = async () => {
  signOut(_auth);
};

export const getFirebaseErrorMsg = (code: string) => {
  const errorMsgIntl = `firebase.error.${code}`;
  const msg = i18n.t(errorMsgIntl);
  return msg === errorMsgIntl ? i18n.t(`firebase.error.default`) : msg;
};

export const verifyFirebasePasswordResetCode = async (
  oob: string,
): Promise<{
  status: boolean;
  data: string;
}> => {
  return await verifyPasswordResetCode(_auth, oob)
    .then((email) => {
      return { status: true, data: email };
    })
    .catch((error) => {
      // Invalid or expired action code. Ask user to try to reset the password
      // again.
      let _msg = '';
      if (error instanceof FirebaseError) {
        console.log(error.code);
        console.log(error.message);
        const msg = getFirebaseErrorMsg(error.code);
        _msg = msg;
        // notificationController.error({
        //   message: i18n.t('common.newPassword'),
        //   description: msg,
        // });
      } else {
        _msg = error.message ?? i18n.t('results.defaultError');
      }
      return { status: false, data: _msg };
    });
};

export const resetPassword = async (newPassword: string, oob: string): Promise<boolean> => {
  return await confirmPasswordReset(_auth, oob, newPassword)
    .then((resp) => {
      notificationController.success({
        message: i18n.t('newPassword.successMessage'),
        description: i18n.t('newPassword.successDescription'),
      });
      return true;
    })
    .catch((error) => {
      // Error occurred during confirmation. The code might have expired or the
      // password is too weak.
      if (error instanceof FirebaseError) {
        console.log(error.code);
        console.log(error.message);
        const msg = getFirebaseErrorMsg(error.code);
        notificationController.error({
          message: i18n.t('common.newPassword'),
          description: msg,
        });
      } else {
        notificationController.error({ message: error.message ?? i18n.t('results.defaultError') });
      }
      return false;
    });
};

export const changeFirebasePassword = async (
  newPassword: string,
): Promise<{
  status: boolean;
  code: string;
}> => {
  const _user = _auth.currentUser;
  if (_user) {
    try {
      await updatePassword(_user, newPassword);
      notificationController.success({
        message: i18n.t('profile.nav.securitySettings.changePassword'),
        description: i18n.t('changePassword.successMessage'),
      });
      return {
        status: true,
        code: '',
      };
    } catch (error: any) {
      let _code = '';
      if (error instanceof FirebaseError) {
        console.log(error.code);
        console.log(error.message);
        if (error.code === 'auth/requires-recent-login') {
          return {
            status: false,
            code: 'auth/requires-recent-login',
          };
        }
        _code = error.code;
        const msg = getFirebaseErrorMsg(error.code);
        notificationController.error({
          message: i18n.t('profile.nav.securitySettings.changePassword'),
          description: msg,
        });
      } else {
        notificationController.error({ message: error?.message ?? i18n.t('results.defaultError') });
      }
      return {
        status: false,
        code: _code,
      };
    }
  } else {
    return {
      status: false,
      code: '',
    };
  }
};
