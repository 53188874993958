import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Avatar, Space } from 'antd';
import { Table } from 'components/common/Table/Table';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { Card } from '@app/components/common/Card/Card';
import defaultAvatar from 'assets/images/default-avatar.jpg';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { notificationController } from '@app/controllers/notificationController';
import { Popconfirm } from '@app/components/common/Popconfirm/Popconfirm';
import { Button } from '@app/components/common/buttons/Button/Button';
import { CommentTableRow, Pagination, getCommentLv2List } from '@app/api/comment.api';
import { useLocation, useParams } from 'react-router-dom';
import { doDeleteCommentLv2 } from '@app/store/slices/commentSlice';
import { useMounted } from '@app/hooks/useMounted';

const initialPagination: Pagination = {
  current: 1,
  pageSize: 10,
  total: 0,
};

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const CommentLv2ManagerPage: React.FC = () => {
  const { commentId } = useParams();
  const query = useQuery();

  const [tableData, setTableData] = useState<{ data: CommentTableRow[]; pagination: Pagination; loading: boolean }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isMounted } = useMounted();

  const _commentId = commentId ? +commentId : -1;

  const fetch = useCallback(
    (pagination: Pagination) => {
      setTableData((tableData) => ({ ...tableData, loading: true }));
      getCommentLv2List(_commentId, {
        page: pagination.current,
        limit: pagination.pageSize,
        sortBy: 'createdAt',
        order: 'desc',
      }).then((res) => {
        setTableData({
          data: res.data.data,
          pagination: { ...pagination, total: res.data.total },
          loading: false,
        });
      });
    },
    [isMounted],
  );

  useEffect(() => {
    fetch(initialPagination);
  }, [fetch]);

  const handleDelete = (rowId: number) => {
    dispatch(doDeleteCommentLv2(rowId))
      .unwrap()
      .then((res) => {
        notificationController.success({
          message: 'Delete comment',
          description: 'Successfully',
        });
        fetch(initialPagination);
      });
  };

  const handleTableChange = (pagination: Pagination) => {
    fetch(pagination);
  };

  const columns: ColumnsType<CommentTableRow> = [
    {
      title: 'Name',
      dataIndex: 'author',
      key: 'author',
      render: (record) => (
        <Space size="middle">
          <Avatar shape="circle" src={record?.photo ? record.photo.path : defaultAvatar} />
          <p>{record ? `${record.firstName} ${record.lastName}` : 'Anonymous'}</p>
        </Space>
      ),
    },
    {
      title: 'Content',
      dataIndex: 'content',
      key: 'content',
    },
    {
      title: 'Liked',
      dataIndex: 'likedCount',
      key: 'likedCount',
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (id) => {
        return (
          <Space>
            <Popconfirm
              title="Are you sure to delete this comment?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => handleDelete(id)}
            >
              <Button type="link">Delete</Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Card padding="0 1.875rem" title={`Comment ${query.get('video')}`}>
        <Table
          columns={columns}
          pagination={tableData.pagination}
          dataSource={tableData.data}
          onChange={handleTableChange}
          loading={tableData.loading}
          scroll={{ x: 800 }}
          bordered
        />
      </Card>
    </>
  );
};

export default CommentLv2ManagerPage;
