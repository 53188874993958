import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Input } from '@app/components/common/inputs/Input/Input';
import { notificationController } from '@app/controllers/notificationController';
import { useResetFormOnCloseModal } from '@app/components/forms/ControlForm/useResetFormOnCloseModal';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { Pagination, getCategoryList } from '@app/api/category.api';
import { Upload } from '@app/components/common/Upload/Upload';
import { UploadOutlined } from '@ant-design/icons';
import { Button } from '@app/components/common/buttons/Button/Button';
import axios from 'axios';
import { CategoryModel } from '@app/domain/CategoryModel';
import { doAddNewVideo } from '@app/store/slices/videoSlice';
import { NewVideoData, VimeoSelectTableRow, VimeoVideo, getVimeoVideoList } from '@app/api/video.api';
import TextArea from 'antd/lib/input/TextArea';
import { resizeThumbnail } from '@app/utils/utils';
import { TableRowSelection } from 'antd/lib/table/interface';
import { ColumnsType } from 'antd/es/table/interface';
import { Table } from '@app/components/common/Table/Table';
import { Tabs } from '@app/components/common/Tabs/Tabs';
import { v4 as uuidv4 } from 'uuid';
import { Select } from '@app/components/common/selects/Select/Select';
import { languageOptions } from '@app/constants/languages';

interface AddUserFormModalProps {
  open: boolean;
  onCancel: () => void;
  onFinish: () => void;
}

interface OptionValue {
  id: number;
  name: string;
  title?: string;
}

const initialPagination: Pagination = {
  current: 1,
  pageSize: 10,
  total: 0,
};

const initialVimeoPagination: Pagination = {
  current: 1,
  pageSize: 25,
  total: 0,
};

export const AddVideoFormModal: React.FC<AddUserFormModalProps> = ({ open, onCancel, onFinish }) => {
  const [form] = BaseForm.useForm();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isInitial, setIsInitial] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const [tableData, setTableData] = useState<{
    data: OptionValue[];
    pagination: Pagination;
    loading: boolean;
  }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState<{ [key: string]: number[] }>({});
  const [selectedCategories, setSelectedCategories] = useState<{ [key: string]: OptionValue[] }>({});

  const [vimeoTableData, setVimeoTableData] = useState<{
    data: VimeoSelectTableRow[];
    pagination: Pagination;
    loading: boolean;
  }>({
    data: [],
    pagination: initialVimeoPagination,
    loading: false,
  });
  const [selectedVimeoId, setSelectedVimeoId] = useState<string>();

  const [activeTabKey, setActiveTabKey] = useState('vimeo_id_upload');

  useResetFormOnCloseModal({
    form,
    open,
  });

  const onOk = async () => {
    await syncCurrentPageCategoriesToForm();
    form.submit();
  };

  const onFormCancel = async () => {
    setSelectedRowKeys({});
    setSelectedCategories({});
    setSelectedVimeoId(undefined);
    setActiveTabKey('vimeo_id_upload');
    onCancel && onCancel();
  };

  const fetchCategories = useCallback((pagination: Pagination) => {
    setTableData((tableData) => ({ ...tableData, loading: true }));
    getCategoryList({ page: pagination.current, limit: pagination.pageSize }).then((res) => {
      setTableData({
        data: res.data.data.map((v: CategoryModel) => ({ id: v.id, name: v.name, title: v.title })),
        pagination: { ...pagination, total: res.data.total },
        loading: false,
      });
    });
  }, []);

  const fetchVimeoVideos = useCallback((pagination: Pagination) => {
    setVimeoTableData((tableData) => ({ ...tableData, loading: true }));
    getVimeoVideoList({ page: pagination.current, limit: pagination.pageSize }).then((res) => {
      const _tableData: VimeoSelectTableRow[] = res.data.data?.map((v: VimeoVideo) => ({
        name: v.name,
        vimeoId: v.uri.split('/').pop(),
      }));
      setVimeoTableData({
        data: _tableData,
        pagination: { ...pagination, total: res.data.total },
        loading: false,
      });
    });
  }, []);

  useEffect(() => {
    if (!isInitial && open) {
      setIsInitial(true);
      fetchVimeoVideos(initialVimeoPagination);
      fetchCategories(initialPagination);
    }
  }, [open]);

  const handleSubmit = (values: NewVideoData) => {
    setLoading(true);
    const _file: File | undefined = values.files.pop();
    const newParam = _file ? { ...values, thumbnail: `video/${_file?.name}` } : { ...values };

    const _categories: OptionValue[] = [];
    Object.keys(selectedCategories).forEach((key) => {
      _categories.push(...selectedCategories[key]);
    });
    // console.log(_categories);

    dispatch(doAddNewVideo({ ...newParam, categories: _categories }))
      .unwrap()
      .then(() => {
        setLoading(false);
        notificationController.success({
          message: 'Create new video',
          description: 'Successfully',
        });
        setSelectedRowKeys({});
        setSelectedCategories({});
        setSelectedVimeoId(undefined);
        setActiveTabKey('vimeo_id_upload');
        onFinish();
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const normFile = (e = { fileList: [] }) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  // const uploadProps = {
  //   name: 'thumbnail',
  //   multiple: false,
  //   action: 'http://localhost:3001/api/v1/files/upload-image',
  //   onChange: (info: any) => {
  //     const { status } = info.file;
  //     if (status !== 'uploading') {
  //       console.log(info.file, info.fileList);
  //     }
  //     if (status === 'done') {
  //       // message.success(t('uploads.successUpload', { name: info.file.name }));
  //     } else if (status === 'error') {
  //       // message.error(t('uploads.failedUpload', { name: info.file.name }));
  //     }
  //   },
  // };

  const handleUpload = async (options: { file: any; onSuccess?: any; onError?: any; onProgress?: any }) => {
    const { onSuccess, onError, file, onProgress } = options;
    setLoading(true);

    const fmData = new FormData();
    const config = {
      headers: { 'content-type': 'multipart/form-data' },

      onUploadProgress: (event: any) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        setProgress(percent);
        if (percent === 100) {
          setTimeout(() => setProgress(0), 1000);
        }
        // onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    const _uuid = uuidv4();
    try {
      const thumbnail = await resizeThumbnail(file);
      fmData.append('file', thumbnail as Blob, `${_uuid}.${(thumbnail as Blob).name?.split('.').pop() ?? ''}`);
      fmData.append('folderId', 'video');
      fmData.append('mediaId', (fmData.get('file') as File).name);
    } catch (error) {
      console.log('[resizeThumbnail] error:', error);
      fmData.append('file', file, `${_uuid}.${(file as Blob).name?.split('.').pop() ?? ''}`);
      fmData.append('folderId', 'video');
      fmData.append('mediaId', (fmData.get('file') as File).name);
    }

    try {
      const res = await axios.post(`${process.env.REACT_APP_BASE_URL}files/upload`, fmData, config);
      // console.log("server res: ", res);
      onSuccess('Ok');
      form.setFieldsValue({ thumbnail: res.data.path, files: [fmData.get('file')] });
    } catch (err) {
      // console.log("Eroor: ", err);
      // const error = new Error("Some error");
      onError({ err });
    }
    setLoading(false);
  };

  const syncCurrentPageCategoriesToForm = async () => {
    const _categories = tableData.data.filter((value) =>
      selectedRowKeys[`${tableData.pagination.current ?? 0}`]?.includes(+value.id),
    );
    setSelectedCategories((value) => ({ ...value, [`${tableData.pagination.current ?? 0}`]: _categories }));
    return _categories;
  };

  const handleTableChange = async (pagination: Pagination) => {
    await syncCurrentPageCategoriesToForm();
    fetchCategories(pagination);
  };

  const onSelectedRowKeysChange = (selectedRowKeys: React.Key[]) => {
    setSelectedRowKeys((value) => ({
      ...value,
      [`${tableData.pagination.current ?? 0}`]: selectedRowKeys as number[],
    }));
  };

  const rowSelection: TableRowSelection<OptionValue> = {
    selectedRowKeys: selectedRowKeys[`${tableData.pagination.current ?? 0}`] ?? [],
    onChange: onSelectedRowKeysChange,
  };

  const columns: ColumnsType<OptionValue> = [
    {
      title: 'Category',
      dataIndex: 'title',
      key: 'title',
      // sorter: (a: OptionValue, b: OptionValue) => (a.title ?? '').localeCompare(b.title ?? ''),
    },
  ];

  const onChangeTab = (key: string) => {
    // console.log(key);
    setActiveTabKey(key);
  };

  const handleUploadVideo = async (options: { file: any; onSuccess?: any; onError?: any; onProgress?: any }) => {
    const { onSuccess, onError, file, onProgress } = options;
    setLoading(true);

    const url = `${process.env.REACT_APP_BASE_URL}video/upload/vimeo`;

    const fmData = new FormData();

    fmData.append('file', file);
    // fmData.append('videoId', data.id + '');
    // fmData.append('folderId', data.category.name);
    fmData.append('mediaId', options.file.name);
    const config = {
      headers: { 'content-type': 'multipart/form-data' },

      onUploadProgress: (event: any) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        setProgress(percent);
        if (percent === 100) {
          setTimeout(() => setProgress(0), 1000);
        }
        // onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    try {
      const res = await axios.post(url, fmData, config);

      onSuccess('Ok');
      form.setFieldsValue({ vimeoId: res.data.vimeoId });
      setSelectedVimeoId(undefined);
    } catch (err) {
      onError({ err });
    }
    setLoading(false);
  };

  // vimeo table
  const handleVimeoTableChange = async (pagination: Pagination) => {
    // await syncCurrentPageCategoriesToForm();
    fetchVimeoVideos(pagination);
  };

  const onVimeoSelectedRowKeysChange = (selectedRowKeys: React.Key[]) => {
    const _id = selectedRowKeys[0] as string;
    setSelectedVimeoId(_id ?? '');
    _id && form.setFieldsValue({ vimeoId: _id });
  };

  const vimeoRowSelection: TableRowSelection<OptionValue> = {
    selectedRowKeys: selectedVimeoId ? [selectedVimeoId] : [],
    onChange: onVimeoSelectedRowKeysChange,
    type: 'radio',
  };

  const vimeoColumns: ColumnsType<VimeoSelectTableRow> = [
    {
      title: 'Vimeo id',
      dataIndex: 'vimeoId',
      key: 'vimeoId',
      width: 120,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      // sorter: (a: VimeoSelectTableRow, b: VimeoSelectTableRow) => (a.name ?? '').localeCompare(b.name ?? ''),
    },
  ];

  return (
    <Modal
      title="New video"
      open={open}
      onOk={onOk}
      onCancel={onFormCancel}
      confirmLoading={isLoading}
      maskClosable={false}
    >
      <BaseForm form={form} layout="vertical" name="addVideoForm" onFinish={handleSubmit}>
        <BaseForm.Item name="title" label="Title" rules={[{ required: true, message: t('common.requiredField') }]}>
          <Input />
        </BaseForm.Item>
        <BaseForm.Item name="lang" label="Language" rules={[{ required: true, message: t('common.requiredField') }]}>
          <Select options={languageOptions} />
        </BaseForm.Item>
        <BaseForm.Item
          name="description"
          label="Description"
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <TextArea rows={4} />
        </BaseForm.Item>
        <BaseForm.Item name="type" label="Type" rules={[{ required: true, message: t('common.requiredField') }]}>
          <Select
            style={{ minWidth: 120 }}
            placeholder="Select type"
            width="100%"
            options={[
              { key: '1', value: 'free', label: 'Free' },
              { key: '2', value: 'login-required', label: 'Login Required' },
              { key: '3', value: 'pro', label: 'Pro' },
            ]}
          />
        </BaseForm.Item>
        {/* <BaseForm.Item
          name="description_short"
          label="Short description"
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <TextArea rows={4} />
        </BaseForm.Item> */}
        <BaseForm.Item label="Category" name="categories" rules={[{ required: false }]}>
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={tableData.data}
            loading={tableData.loading}
            pagination={tableData.pagination}
            onChange={handleTableChange}
            // scroll={{ x: 300 }}
            bordered
            rowKey={(record) => record.id}
          />
        </BaseForm.Item>
        <BaseForm.Item
          name="files"
          label="Thumbnail"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          initialValue={[]}
          rules={[{ required: true }]}
        >
          <Upload accept="image/*" maxCount={1} customRequest={handleUpload}>
            <Button type="default" icon={<UploadOutlined />}>
              {t('forms.validationFormLabels.clickToUpload')}
            </Button>
          </Upload>
        </BaseForm.Item>
        <BaseForm.Item
          name="vimeoId"
          label={t('vimeo.linkWithVimeo')}
          initialValue={undefined}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Input disabled />
        </BaseForm.Item>
        <BaseForm.Item>
          <Tabs
            activeKey={activeTabKey}
            onChange={onChangeTab}
            type="card"
            items={[
              {
                label: t('common.upload'),
                key: 'vimeo_id_upload',
                children: (
                  <div>
                    {open && (
                      <Upload accept="video/mp4" customRequest={handleUploadVideo}>
                        <Button type="default" icon={<UploadOutlined />}>
                          {t('forms.validationFormLabels.clickToUpload')}
                        </Button>
                      </Upload>
                    )}
                  </div>
                ),
              },
              {
                label: t('common.chooseExisting'),
                key: 'vimeo_id_choose_exist',
                children: (
                  <div>
                    <Table
                      rowSelection={vimeoRowSelection}
                      columns={vimeoColumns}
                      dataSource={vimeoTableData.data}
                      loading={vimeoTableData.loading}
                      pagination={vimeoTableData.pagination}
                      onChange={handleVimeoTableChange}
                      scroll={{ x: 400 }}
                      bordered
                      rowKey={(record: VimeoSelectTableRow) => record.vimeoId}
                    />
                  </div>
                ),
              },
            ]}
          />
        </BaseForm.Item>
      </BaseForm>
    </Modal>
  );
};
