import React, { useEffect, useState, useCallback } from 'react';
import { Avatar, Space, Tooltip } from 'antd';
import { Table } from 'components/common/Table/Table';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { Card } from '@app/components/common/Card/Card';
import { PaginationOrder, PaginationSortBy, Status, UserTableRow, getUserList } from '@app/api/users.api';
import defaultAvatar from 'assets/images/default-avatar.jpg';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { notificationController } from '@app/controllers/notificationController';
import { doActiveUser, doBulkDeleteUser, doDeleteUser, doDisableUser } from '@app/store/slices/userSlice';
import { Popconfirm } from '@app/components/common/Popconfirm/Popconfirm';
import { Button } from '@app/components/common/buttons/Button/Button';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { UpdateUserFormModal } from '@app/components/user/UpdateUserForm/UpdateUserForm';
import { CaretDownFilled, CaretUpFilled, PlusOutlined, SwapOutlined } from '@ant-design/icons';
import { AddUserFormModal } from '@app/components/user/AddUserForm/AddUserForm';
import { UserDetailModal } from '@app/components/user/DetailUser/DetailUser';
import { useMounted } from '@app/hooks/useMounted';
import { Pagination } from '@app/api/users.api';
import { TableRowSelection } from 'antd/lib/table/interface';
import dayjs from 'dayjs';

export enum StatusEnum {
  'active' = 1,
  'inactive' = 2,
}

const initialPagination: Pagination = {
  current: 1,
  pageSize: 10,
  total: 0,
  defaultPageSize: 10,
  showSizeChanger: true,
  pageSizeOptions: ['10', '50', '100'],
};

const UserManagerPage: React.FC = () => {
  const [tableData, setTableData] = useState<{
    data: UserTableRow[];
    pagination: Pagination;
    loading: boolean;
    sort: {
      sortBy: PaginationSortBy;
      order: PaginationOrder;
    };
  }>({
    data: [],
    pagination: initialPagination,
    loading: false,
    sort: {
      sortBy: 'createdAt',
      order: 'desc',
    },
  });
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  // const [isLoading, setLoading] = useState(false);
  // const [doReload, setDoReload] = useState<boolean>(false);
  const [itemSelected, setItemSelected] = useState<UserTableRow | null>(null);
  const [isDetailOpen, setIsDetailOpen] = useState<boolean>(false);
  const [isCreateOpen, setIsCreateOpen] = useState<boolean>(false);
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const { isMounted } = useMounted();
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  // const { pagination, setPagination } = useState<Pagination>({ ...initialPagination });

  const fetch = useCallback(
    (pagination: Pagination, sort: { sortBy: PaginationSortBy; order: PaginationOrder }) => {
      setTableData((tableData) => ({ ...tableData, sort: sort, loading: true }));
      getUserList({
        page: pagination.current,
        limit: pagination.pageSize,
        sortBy: sort.sortBy,
        order: sort.order,
      }).then((res) => {
        setTableData({
          data: res.data.data,
          pagination: { ...pagination, total: res.data.total },
          loading: false,
          sort: sort,
        });
      });
    },
    [isMounted],
  );

  // const fetchUsers = useCallback(
  //   (doReload: boolean) => {
  //     if (doReload) {
  //       getUserList({ page: 1, limit: 10 })
  //         .then((res) => {
  //           setTableData(res.data);
  //         })
  //         .finally(() => {
  //           setLoading(false);
  //         });
  //     }
  //   },
  //   [doReload],
  // );

  useEffect(() => {
    fetch(initialPagination, {
      sortBy: 'createdAt',
      order: 'desc',
    });
  }, [fetch]);

  const handleDisable = (rowId: number) => {
    dispatch(doDisableUser(rowId))
      .unwrap()
      .then((res) => {
        notificationController.success({
          message: 'Ban user',
          description: 'Successfully',
        });
        fetch(tableData.pagination, tableData.sort);
      });
  };

  const handleActiveUser = (rowId: number) => {
    dispatch(doActiveUser(rowId))
      .unwrap()
      .then((res) => {
        notificationController.success({
          message: 'Active user',
          description: 'Successfully',
        });
        fetch(tableData.pagination, tableData.sort);
      });
  };

  const handleDelete = (rowId: number) => {
    dispatch(doDeleteUser(rowId))
      .unwrap()
      .then((res) => {
        notificationController.success({
          message: 'Remove user',
          description: 'Successfully',
        });
        setSelectedRowKeys([]);
        fetch(tableData.pagination, tableData.sort);
      });
  };

  const handleBulkDelete = () => {
    if (selectedRowKeys.length <= 0) {
      return;
    }
    dispatch(doBulkDeleteUser(selectedRowKeys))
      .unwrap()
      .then((res) => {
        notificationController.success({
          message: 'Remove users',
          description: 'Successfully',
        });
        setSelectedRowKeys([]);
        fetch(tableData.pagination, tableData.sort);
      });
  };

  const handleEdit = (record: UserTableRow) => {
    setItemSelected(record);
    setTimeout(() => {
      setIsEditOpen(true);
    }, 250);
  };
  const handleOpenDetail = (record: UserTableRow) => {
    setItemSelected(record);
    setTimeout(() => {
      setIsDetailOpen(true);
    }, 250);
  };
  const openNewUserModal = () => {
    setIsCreateOpen(true);
  };
  const hideNewVideoModal = () => {
    setIsCreateOpen(false);
  };
  const handleTableChange = (pagination: Pagination) => {
    setSelectedRowKeys([]);
    fetch(pagination, tableData.sort);
  };

  const onSorterPress = (sortBy: PaginationSortBy) => {
    if (tableData.sort.sortBy === sortBy) {
      fetch(
        {
          ...initialPagination,
          pageSize: tableData.pagination.pageSize,
        },
        { sortBy: sortBy, order: tableData.sort.order === 'asc' ? 'desc' : 'asc' },
      );
    } else {
      fetch(
        {
          ...initialPagination,
          pageSize: tableData.pagination.pageSize,
        },
        { sortBy: sortBy, order: 'asc' },
      );
    }
  };

  const renderSorterHeader = (title: string, key: PaginationSortBy) => {
    let _tooltip = t('dataDisplay.pagination.clickToSortAsc');
    let _orderAsc: boolean | undefined = undefined;
    if (tableData.sort.sortBy === key) {
      if (tableData.sort.order === 'asc') {
        _tooltip = t('dataDisplay.pagination.clickToSortDesc');
        _orderAsc = true;
      } else {
        _tooltip = t('dataDisplay.pagination.clickToSortAsc');
        _orderAsc = false;
      }
    }
    return (
      <Tooltip placement="topLeft" title={_tooltip}>
        <a onClick={() => onSorterPress(key)}>
          {title}{' '}
          {_orderAsc !== undefined ? (
            _orderAsc === true ? (
              <CaretUpFilled size={14} />
            ) : (
              <CaretDownFilled size={14} />
            )
          ) : (
            <SwapOutlined size={14} />
          )}
        </a>
      </Tooltip>
    );
  };

  const columns: ColumnsType<UserTableRow> = [
    {
      title: () => renderSorterHeader('Name', 'name'),
      dataIndex: 'firstName',
      key: 'firstName',
      render: (_, record) => (
        <Space size="middle">
          <Avatar shape="circle" src={record.photo ? record.photo.path : defaultAvatar} />
          <a onClick={() => handleOpenDetail(record)}>
            {record.firstName} {record.lastName}
          </a>
        </Space>
      ),
    },
    {
      title: 'Provider',
      dataIndex: 'provider',
      key: 'provider',
    },
    {
      title: () => renderSorterHeader('Email', 'email'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: (item) => <div>{item.name}</div>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (item: Status) => <div>{item.name}</div>,
    },
    {
      title: 'Verified',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (isVerified: boolean) => <div>{isVerified ? 'Yes' : 'No'}</div>,
    },
    {
      title: () => renderSorterHeader('Created at', 'createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 130,
      render: (date: string) => <span>{dayjs(date).format('DD/MM/YYYY, HH:mm:ss')}</span>,
      // sorter: (a: UserTableRow, b: UserTableRow) => dayjs(a.createdAt).unix() - dayjs(b.createdAt).unix(),
      // defaultSortOrder: 'descend',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => {
        return (
          <Space>
            <Button type="link" onClick={() => handleEdit(record)}>
              Edit
            </Button>
            {record.status && record.status.id === StatusEnum['active'] && (
              <Popconfirm
                title="Are you sure to disable this user?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => handleDisable(record.id)}
              >
                <Button type="link">Ban</Button>
              </Popconfirm>
            )}
            {record.status && record.status.id === StatusEnum['inactive'] && (
              <Popconfirm
                title="Are you sure to active this user?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => handleActiveUser(record.id)}
              >
                <Button type="link">UnBan</Button>
              </Popconfirm>
            )}
            <Popconfirm
              title="Are you sure to delete this user?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => handleDelete(record.id)}
            >
              <Button type="link">Delete</Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const onSelectedRowKeysChange = (selectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(selectedRowKeys as number[]);
  };

  // const selectRow = (record: UserTableRow) => {
  //   const _selectedRowKeys = [...selectedRowKeys];
  //   if (_selectedRowKeys.indexOf(record.id) >= 0) {
  //     _selectedRowKeys.splice(_selectedRowKeys.indexOf(record.id), 1);
  //   } else {
  //     _selectedRowKeys.push(record.id);
  //   }
  //   setSelectedRowKeys(_selectedRowKeys);
  // };

  const rowSelection: TableRowSelection<UserTableRow> = {
    selectedRowKeys,
    onChange: onSelectedRowKeysChange,
  };

  return (
    <>
      <Card
        padding="0 1.875rem"
        title="User list"
        extra={
          <div style={{ display: 'flex' }}>
            <Button type="primary" icon={<PlusOutlined />} onClick={openNewUserModal}>
              Create new
            </Button>
            <Popconfirm
              title="Are you sure to delete selected users?"
              okText="Yes"
              cancelText="No"
              onConfirm={handleBulkDelete}
            >
              <Button style={{ marginLeft: 15 }} type="ghost" disabled={selectedRowKeys.length <= 0}>
                Delete
              </Button>
            </Popconfirm>
          </div>
        }
      >
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={tableData.data}
          loading={tableData.loading}
          pagination={{ ...tableData.pagination, style: { alignItems: 'center' } }}
          onChange={handleTableChange}
          scroll={{ x: 1400 }}
          bordered
          rowKey={(record) => record.id}
          // onRow={(record: UserTableRow & { key: number }) => ({
          //   onClick: () => {
          //     selectRow(record);
          //   },
          // })}
        />
      </Card>
      <BaseButtonsForm.Provider
        onFormFinish={(name) => {
          if (name === 'updateUserForm') {
            setIsEditOpen(false);
          } else if (name === 'addUserForm') {
            setIsCreateOpen(false);
          }
        }}
      >
        {itemSelected && (
          <UserDetailModal
            open={isDetailOpen}
            onCancel={() => {
              setItemSelected(null);
              setIsDetailOpen(false);
            }}
            data={itemSelected}
          />
        )}
        <AddUserFormModal
          open={isCreateOpen}
          onCancel={hideNewVideoModal}
          onFinish={() => {
            setTimeout(() => {
              fetch(tableData.pagination, tableData.sort);
            }, 250);
          }}
        />
        {itemSelected && (
          <UpdateUserFormModal
            open={isEditOpen}
            onCancel={() => setItemSelected(null)}
            onFinish={() => {
              setTimeout(() => {
                setItemSelected(null);
                fetch(tableData.pagination, tableData.sort);
              }, 250);
            }}
            data={itemSelected}
          />
        )}
      </BaseButtonsForm.Provider>
    </>
  );
};

export default UserManagerPage;
