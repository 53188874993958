import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GeneratorModel } from '@app/domain/CouponModel';
import { generateCoupons, GeneratorData } from '@app/api/coupon.api';

export interface CouponsState {
  generators: GeneratorModel[] | null;
}

const initialState: CouponsState = {
  generators: [],
};

export const doGenerator = createAsyncThunk('coupons/doGenerator', async (data: GeneratorData) =>
  generateCoupons(data),
);

export const couponSlice = createSlice({
  name: 'comment',
  initialState,
  reducers: {},
});

export default couponSlice.reducer;
