import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Input } from '@app/components/common/inputs/Input/Input';
import { notificationController } from '@app/controllers/notificationController';
import { useResetFormOnCloseModal } from '@app/components/forms/ControlForm/useResetFormOnCloseModal';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { NewUserData } from '@app/api/users.api';
import { doAddUser } from '@app/store/slices/userSlice';
import { Select } from '@app/components/common/selects/Select/Select';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { Switch } from '@app/components/common/Switch/Switch';

interface AddUserFormModalProps {
  open: boolean;
  onCancel: () => void;
  onFinish: () => void;
}

interface OptionValue {
  key: string;
  value: string;
}

const roles: OptionValue[] = [
  { key: '1', value: 'admin' },
  { key: '2', value: 'user' },
];

export const AddUserFormModal: React.FC<AddUserFormModalProps> = ({ open, onCancel, onFinish }) => {
  const [form] = BaseForm.useForm();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);
  const [roleSelected, setRoleSelected] = useState<OptionValue>();
  const [status, setStatus] = useState<boolean>(false);

  useResetFormOnCloseModal({
    form,
    open,
  });

  const onOk = () => {
    form.submit();
  };

  // useEffect(() => {
  //   getCategoryList({ page: 1, limit: 50 })
  //     .then((res) => {
  //       setCategories(res.data.data.map((v: CategoryModel) => ({ key: v.id, value: v.name })));
  //     })
  //     .finally(() => setLoading(false));
  // }, []);

  const handleSubmit = (values: NewUserData) => {
    setLoading(true);
    const roleId = roleSelected ? +roleSelected.key : 2;
    const statusId = status ? 1 : 2;
    dispatch(doAddUser({ ...values, role: { id: roleId }, status: { id: statusId } }))
      .unwrap()
      .then(() => {
        setLoading(false);
        notificationController.success({
          message: 'Add new user',
          description: 'Successfully',
        });
        onFinish();
      })
      .catch((err) => {
        // notificationController.error({ message: err.message });
        setLoading(false);
      });
  };

  return (
    <Modal title="Add user" open={open} onOk={onOk} onCancel={onCancel} confirmLoading={isLoading} maskClosable={false}>
      <BaseForm form={form} layout="vertical" name="addUserForm" onFinish={handleSubmit}>
        <BaseForm.Item
          name="firstName"
          label="First name"
          rules={[{ required: false, message: t('common.requiredField') }]}
        >
          <Input />
        </BaseForm.Item>
        <BaseForm.Item
          name="lastName"
          label="Last name"
          rules={[{ required: false, message: t('common.requiredField') }]}
        >
          <Input />
        </BaseForm.Item>
        <BaseForm.Item name="email" label="Email" rules={[{ required: true, message: t('common.requiredField') }]}>
          <Input />
        </BaseForm.Item>
        <Auth.FormItem
          label={t('common.password')}
          name="password"
          rules={[
            {
              pattern: new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\w~`@$!%*#?&^\(\)\-+=|\\:;"'<>,.\{\}\[\]]{7,}$/),
              message: t('common.passwordInvalid'),
            },
            { required: true, message: t('common.requiredField') },
          ]}
        >
          <Auth.FormInputPassword placeholder={t('common.password')} />
        </Auth.FormItem>
        <BaseForm.Item name="role" label="Role" rules={[{ required: true, message: t('common.requiredField') }]}>
          <Select
            options={roles}
            onSelect={(v, _record) => {
              setRoleSelected(_record as OptionValue);
            }}
          />
        </BaseForm.Item>
        <BaseForm.Item name="status" label="Status" rules={[{ required: false, message: t('common.requiredField') }]}>
          <Switch checked={status} onChange={() => setStatus(!status)} />
        </BaseForm.Item>
      </BaseForm>
    </Modal>
  );
};
