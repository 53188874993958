import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Input } from '@app/components/common/inputs/Input/Input';
import { notificationController } from '@app/controllers/notificationController';
import { useResetFormOnCloseModal } from '@app/components/forms/ControlForm/useResetFormOnCloseModal';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { doAddNewCategory } from '@app/store/slices/categorySlice';
import { Upload } from '@app/components/common/Upload/Upload';
import { Button } from '@app/components/common/buttons/Button/Button';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import { SelectedVideosModel } from '@app/domain/CategoryModel';
import { Table } from '@app/components/common/Table/Table';
import { Pagination, VideoSelectTableRow, getVideoList } from '@app/api/video.api';
import { ColumnsType, TableRowSelection } from 'antd/es/table/interface';
import { v4 as uuidv4 } from 'uuid';
import { Select } from '@app/components/common/selects/Select/Select';
import { languageOptions } from '@app/constants/languages';

interface AddUserFormModalProps {
  open: boolean;
  onCancel: () => void;
  onFinish: () => void;
}
interface NewCategoryFormData {
  title: string;
  name: string;
  photo?: string;
  files: File[];
  videos: SelectedVideosModel[];
}
const initialPagination: Pagination = {
  current: 1,
  pageSize: 10,
  total: 0,
};

export const AddCategoryFormModal: React.FC<AddUserFormModalProps> = ({ open, onCancel, onFinish }) => {
  const [form] = BaseForm.useForm();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);
  const [isInitial, setIsInitial] = useState(false);

  const [tableVideosData, setTableVideosData] = useState<{
    data: VideoSelectTableRow[];
    pagination: Pagination;
    loading: boolean;
  }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });

  const [selectedRowKeys, setSelectedRowKeys] = useState<{ [key: string]: number[] }>({});
  const [selectedVideos, setSelectedVideos] = useState<{ [key: string]: VideoSelectTableRow[] }>({});

  useResetFormOnCloseModal({
    form,
    open,
  });

  const onOk = async () => {
    await syncCurrentPageVideoToForm();
    form.submit();
  };

  const onFormCancel = async () => {
    setSelectedRowKeys({});
    setSelectedVideos({});
    onCancel && onCancel();
  };

  const fetchVideos = useCallback((pagination: Pagination) => {
    setTableVideosData((tableData) => ({ ...tableData, loading: true }));
    getVideoList({ page: pagination.current, limit: pagination.pageSize }).then((res) => {
      setTableVideosData({
        data: res.data.data,
        pagination: { ...pagination, total: res.data.total },
        loading: false,
      });
    });
  }, []);

  useEffect(() => {
    if (!isInitial && open) {
      setIsInitial(true);
      fetchVideos(initialPagination);
    }
  }, [open]);

  const handleSubmit = (values: NewCategoryFormData) => {
    setLoading(true);
    const _file: File | undefined = values.files.pop();
    // console.log(_file);
    const newParam = _file ? { ...values, photo: `category/${_file?.name}` } : { ...values };

    const _videos: VideoSelectTableRow[] = [];
    Object.keys(selectedVideos).forEach((key) => {
      _videos.push(...selectedVideos[key]);
    });

    dispatch(doAddNewCategory({ ...newParam, videos: _videos }))
      .unwrap()
      .then(() => {
        setLoading(false);
        notificationController.success({
          message: 'Create new category',
          description: 'Successfully',
        });
        setSelectedRowKeys({});
        setSelectedVideos({});
        onFinish();
      })
      .catch((err) => {
        // notificationController.error({ message: err.message });
        setLoading(false);
      });
  };

  const normFile = (e = { fileList: [] }) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleUpload = async (options: { file: any; onSuccess?: any; onError?: any; onProgress?: any }) => {
    const { onSuccess, onError, file, onProgress } = options;

    const fmData = new FormData();
    const config = {
      headers: { 'content-type': 'multipart/form-data' },

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onUploadProgress: (event: any) => {},
    };
    const _uuid = uuidv4();
    fmData.append('file', file, `${_uuid}.${(file as Blob).name?.split('.').pop() ?? ''}`);
    fmData.append('folderId', 'category');
    fmData.append('mediaId', (fmData.get('file') as File).name);

    // console.log((fmData.get('file') as File).name);

    try {
      const res = await axios.post(`${process.env.REACT_APP_BASE_URL}files/upload`, fmData, config);
      onSuccess('Ok');
      form.setFieldsValue({ thumbnail: res.data.path, files: [fmData.get('file')] });
    } catch (err) {
      onError({ err });
    }
  };

  const syncCurrentPageVideoToForm = async () => {
    const _videos = tableVideosData.data.filter((value) =>
      selectedRowKeys[`${tableVideosData.pagination.current ?? 0}`]?.includes(value.id),
    );
    setSelectedVideos((value) => ({ ...value, [`${tableVideosData.pagination.current ?? 0}`]: _videos }));
    return _videos;
    // form.setFieldsValue({ videos: selectedRows });
  };

  const handleTableChange = async (pagination: Pagination) => {
    await syncCurrentPageVideoToForm();
    fetchVideos(pagination);
  };

  const onSelectedRowKeysChange = (selectedRowKeys: React.Key[]) => {
    setSelectedRowKeys((value) => ({
      ...value,
      [`${tableVideosData.pagination.current ?? 0}`]: selectedRowKeys as number[],
    }));
  };

  const rowSelection: TableRowSelection<VideoSelectTableRow> = {
    selectedRowKeys: selectedRowKeys[`${tableVideosData.pagination.current ?? 0}`] ?? [],
    onChange: onSelectedRowKeysChange,
  };

  const columns: ColumnsType<VideoSelectTableRow> = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      // sorter: (a: VideoSelectTableRow, b: VideoSelectTableRow) => a.title.localeCompare(b.title),
    },
  ];

  return (
    <Modal title="New category" open={open} onOk={onOk} onCancel={onFormCancel} confirmLoading={isLoading}>
      <BaseForm form={form} layout="vertical" name="addCategoryForm" onFinish={handleSubmit}>
        <BaseForm.Item name="title" label="Title" rules={[{ required: true, message: t('common.requiredField') }]}>
          <Input />
        </BaseForm.Item>
        <BaseForm.Item name="lang" label="Language" rules={[{ required: true, message: t('common.requiredField') }]}>
          <Select options={languageOptions} />
        </BaseForm.Item>
        <BaseForm.Item
          name="files"
          label="Icon"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          initialValue={[]}
          rules={[{ required: false }]}
        >
          <Upload accept="image/*" maxCount={1} customRequest={handleUpload}>
            <Button type="default" icon={<UploadOutlined />}>
              {t('forms.validationFormLabels.clickToUpload')}
            </Button>
          </Upload>
        </BaseForm.Item>
        <BaseForm.Item label="Choose Videos" rules={[{ required: false }]}>
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={tableVideosData.data}
            loading={tableVideosData.loading}
            pagination={tableVideosData.pagination}
            onChange={handleTableChange}
            bordered
            rowKey={(record) => record.id}
          />
        </BaseForm.Item>
      </BaseForm>
    </Modal>
  );
};
