import React, { useEffect, useState, useCallback } from 'react';
import { Avatar, Space, Tooltip } from 'antd';
import { Table } from 'components/common/Table/Table';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { Card } from '@app/components/common/Card/Card';
import defaultAvatar from 'assets/images/default-avatar.jpg';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { notificationController } from '@app/controllers/notificationController';
import { Popconfirm } from '@app/components/common/Popconfirm/Popconfirm';
import { Button } from '@app/components/common/buttons/Button/Button';
import { CommentTableRow, Pagination, PaginationOrder, PaginationSortBy, getCommentList } from '@app/api/comment.api';
// import { VideoModel } from '@app/domain/VideoModel';
import {
  doBulkDeleteComment,
  doBulkDeleteCommentLv2,
  doDeleteComment,
  doDeleteCommentLv2,
} from '@app/store/slices/commentSlice';
import { useNavigate } from 'react-router-dom';
import { useMounted } from '@app/hooks/useMounted';
import { TableRowSelection } from 'antd/lib/table/interface';
import dayjs from 'dayjs';
// import { BASE_COLORS } from '@app/styles/themes/constants';
import { CaretDownFilled, CaretUpFilled, ExclamationCircleFilled, SwapOutlined } from '@ant-design/icons';
import { ConfirmModal } from '@app/components/common/Modal/Modal';

const initialPagination: Pagination = {
  current: 1,
  pageSize: 10,
  total: 0,
  defaultPageSize: 10,
  showSizeChanger: true,
  pageSizeOptions: ['10', '50', '100'],
};

const CommentManagerPage: React.FC = () => {
  const [tableData, setTableData] = useState<{
    data: CommentTableRow[];
    pagination: Pagination;
    loading: boolean;
    sort: {
      sortBy: PaginationSortBy;
      order: PaginationOrder;
    };
  }>({
    data: [],
    pagination: initialPagination,
    loading: false,
    sort: {
      sortBy: 'createdAt',
      order: 'desc',
    },
  });
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // const [isLoading, setLoading] = useState(false);
  const { isMounted } = useMounted();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedComments, setSelectedComments] = useState<{ id: number; isReplyComment: boolean }[]>([]);

  const fetch = useCallback(
    (pagination: Pagination, sort: { sortBy: PaginationSortBy; order: PaginationOrder }) => {
      setTableData((tableData) => ({ ...tableData, sort: sort, loading: true }));
      getCommentList({
        page: pagination.current,
        limit: pagination.pageSize,
        sortBy: sort.sortBy,
        order: sort.order,
      }).then((res) => {
        console.log(res);
        setTableData((tableData) => ({
          ...tableData,
          data: res.data.data,
          pagination: { ...pagination, total: res.data.total },
          loading: false,
        }));
      });
    },
    [isMounted],
  );

  // useEffect(() => {
  //   getCommentList({ page: 1, limit: 10 })
  //     .then((res) => setTableData(res.data))
  //     .finally(() => setLoading(true));
  // }, []);
  useEffect(() => {
    fetch(initialPagination, {
      sortBy: 'createdAt',
      order: 'desc',
    });
  }, [fetch]);

  // const fetchComments = useCallback(
  //   (doReload: boolean) => {
  //     if (doReload) {
  //       getCommentList({ page: 1, limit: 10 })
  //         .then((res) => {
  //           setTableData(res.data);
  //         })
  //         .finally(() => {
  //           setLoading(false);
  //         });
  //     }
  //   },
  //   [doReload],
  // );

  // useEffect(() => {
  //   fetchComments(true);
  // }, []);

  const refreshPage = () => {
    setSelectedRowKeys([]);
    setSelectedComments([]);
    fetch(tableData.pagination, tableData.sort);
  };

  const handleDelete = (rowId: number, isReplyComment: boolean) => {
    if (!isReplyComment) {
      dispatch(doDeleteComment(rowId))
        .unwrap()
        .then((res) => {
          notificationController.success({
            message: 'Delete comment',
            description: 'Successfully',
          });
          refreshPage();
        });
    } else {
      dispatch(doDeleteCommentLv2(rowId))
        .unwrap()
        .then((res) => {
          notificationController.success({
            message: 'Delete comment',
            description: 'Successfully',
          });
          refreshPage();
        });
    }
  };

  const handleBulkDelete = () => {
    if (selectedComments.length <= 0) {
      return;
    }
    const _parentIdList = selectedComments.filter((value) => !value.isReplyComment).map((value) => value.id);
    const _childrenIdList = selectedComments.filter((value) => value.isReplyComment).map((value) => value.id);

    if (_parentIdList.length > 0) {
      dispatch(doBulkDeleteComment(_parentIdList))
        .unwrap()
        .then((res) => {
          if (_childrenIdList.length > 0) {
            dispatch(doBulkDeleteCommentLv2(_childrenIdList))
              .unwrap()
              .then((res) => {
                notificationController.success({
                  message: 'Delete comments',
                  description: 'Successfully',
                });
                refreshPage();
              });
          } else {
            notificationController.success({
              message: 'Delete comments',
              description: 'Successfully',
            });
            refreshPage();
          }
        });
    } else if (_childrenIdList.length > 0) {
      dispatch(doBulkDeleteCommentLv2(_childrenIdList))
        .unwrap()
        .then((res) => {
          if (_parentIdList.length > 0) {
            dispatch(doBulkDeleteComment(_parentIdList))
              .unwrap()
              .then((res) => {
                notificationController.success({
                  message: 'Delete comments',
                  description: 'Successfully',
                });
                refreshPage();
              });
          } else {
            notificationController.success({
              message: 'Delete comments',
              description: 'Successfully',
            });
            refreshPage();
          }
        });
    }
  };

  const handleTableChange = (pagination: Pagination) => {
    setSelectedRowKeys([]);
    setSelectedComments([]);
    fetch(pagination, tableData.sort);
  };

  const onSorterPress = (sortBy: PaginationSortBy) => {
    if (tableData.sort.sortBy === sortBy) {
      fetch(
        {
          ...initialPagination,
          pageSize: tableData.pagination.pageSize,
        },
        { sortBy: sortBy, order: tableData.sort.order === 'asc' ? 'desc' : 'asc' },
      );
    } else {
      fetch(
        {
          ...initialPagination,
          pageSize: tableData.pagination.pageSize,
        },
        { sortBy: sortBy, order: 'asc' },
      );
    }
  };

  const renderSorterHeader = (title: string, key: PaginationSortBy) => {
    let _tooltip = t('dataDisplay.pagination.clickToSortAsc');
    let _orderAsc: boolean | undefined = undefined;
    if (tableData.sort.sortBy === key) {
      if (tableData.sort.order === 'asc') {
        _tooltip = t('dataDisplay.pagination.clickToSortDesc');
        _orderAsc = true;
      } else {
        _tooltip = t('dataDisplay.pagination.clickToSortAsc');
        _orderAsc = false;
      }
    }
    return (
      <Tooltip placement="topLeft" title={_tooltip}>
        <a onClick={() => onSorterPress(key)}>
          {title}{' '}
          {_orderAsc !== undefined ? (
            _orderAsc === true ? (
              <CaretUpFilled size={14} />
            ) : (
              <CaretDownFilled size={14} />
            )
          ) : (
            <SwapOutlined size={14} />
          )}
        </a>
      </Tooltip>
    );
  };

  const onChooseDeleteType = (id: number, isReplyComment: boolean) => {
    ConfirmModal({
      title: isReplyComment
        ? 'Are you sure you want to delete the selected reply(ies)?'
        : 'Are you sure you want to delete the selected comment and relies?',
      icon: <ExclamationCircleFilled />,
      content: isReplyComment
        ? 'this only delete this reply comment only'
        : "This will delete all comment and it's replies",
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDelete(id, isReplyComment);
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  };

  const columns: ColumnsType<CommentTableRow> = [
    {
      title: () => renderSorterHeader('Video', 'video'),
      dataIndex: 'videoTitle',
      key: 'videoTitle',
      width: 300,
      render: (item: string) => <span>{item ?? 'Unknown'}</span>,
    },
    {
      title: 'Comment',
      dataIndex: 'content',
      key: 'content',
      width: 250,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="topLeft" title={text} overlayStyle={{ maxWidth: '500px' }}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: () => renderSorterHeader('Author', 'author'),
      dataIndex: 'authorFirstName',
      key: 'authorFirstName',
      width: 200,
      render: (author, record: CommentTableRow) => {
        const _name = `${record.authorFirstName ?? ''} ${record.authorLastName ?? ''}`.trim();
        return (
          <Space size="middle">
            <Avatar shape="circle" src={record?.authorPhoto ? record.authorPhoto : defaultAvatar} />
            <span>{_name.length > 0 ? _name : 'Anonymous'}</span>
          </Space>
        );
      },
    },
    {
      title: 'Reply',
      dataIndex: 'replyContent',
      key: 'replyContent',
      width: 250,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="topLeft" title={text} overlayStyle={{ maxWidth: '500px' }}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: () => renderSorterHeader("Reply's Author", 'authorReply'),
      dataIndex: 'authorReplyFirstName',
      key: 'authorReplyFirstName',
      width: 200,
      render: (author, record: CommentTableRow) => {
        if (!record.replyId) {
          return null;
        }
        const _name = `${record.authorReplyFirstName ?? ''} ${record.authorReplyLastName ?? ''}`.trim();
        return (
          <Space size="middle">
            <Avatar shape="circle" src={record?.authorReplyPhoto ? record.authorReplyPhoto : defaultAvatar} />
            <span>{_name.length > 0 ? _name : 'Anonymous'}</span>
          </Space>
        );
      },
    },
    {
      title: () => renderSorterHeader('Created at', 'createdAt'),
      dataIndex: 'replyCreatedAt',
      key: 'replyCreatedAt',
      width: 130,
      render: (date: string, record: CommentTableRow) => (
        <span>{dayjs(record.replyId ? date : record.createdAt).format('DD/MM/YYYY, HH:mm:ss')}</span>
      ),
      // defaultSortOrder: 'descend',
    },
    {
      title: 'Liked',
      dataIndex: 'replyLikedCount',
      key: 'replyLikedCount',
      render: (text, record) => <span>{record.replyId ? record.replyLikedCount : record.likedCount}</span>,
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (id, record) => {
        return (
          <Space>
            {record.replyId ? (
              <Popconfirm
                title="Which one do you like to delete?"
                okText="Reply only"
                cancelText="Comment & Replies"
                placement="left"
                overlayStyle={{ minWidth: '400px', maxWidth: '800px' }}
                onConfirm={() => record.replyId && onChooseDeleteType(record.replyId, true)}
                onCancel={() => onChooseDeleteType(record.id, false)}
              >
                <Button type="link">Delete</Button>
              </Popconfirm>
            ) : (
              <Popconfirm
                title="Are you sure to delete this comment?"
                okText="Yes"
                cancelText="No"
                placement="left"
                onConfirm={() => handleDelete(id, false)}
              >
                <Button type="link">Delete</Button>
              </Popconfirm>
            )}
          </Space>
        );
      },
    },
  ];

  const onSelectedRowKeysChange = (selectedRowKeys: React.Key[], selectedRows: CommentTableRow[]) => {
    setSelectedRowKeys(selectedRowKeys as number[]);
    setSelectedComments(
      selectedRows.map((value) =>
        value.replyId ? { id: value.replyId, isReplyComment: true } : { id: value.id, isReplyComment: false },
      ),
    );
  };

  const rowSelection: TableRowSelection<CommentTableRow> = {
    selectedRowKeys,
    onChange: onSelectedRowKeysChange,
  };

  return (
    <>
      <Card
        padding="0 1.875rem"
        title="Comment list"
        extra={
          <div style={{ display: 'flex' }}>
            <Popconfirm
              title="Are you sure to delete selected comments?"
              okText="Yes"
              cancelText="No"
              onConfirm={handleBulkDelete}
            >
              <Button style={{ marginLeft: 15 }} type="ghost" disabled={selectedRowKeys.length <= 0}>
                Delete
              </Button>
            </Popconfirm>
          </div>
        }
      >
        <Table
          rowSelection={rowSelection}
          columns={columns}
          pagination={{ ...tableData.pagination, style: { alignItems: 'center' } }}
          dataSource={tableData.data}
          loading={tableData.loading}
          onChange={handleTableChange}
          scroll={{ x: 1600 }}
          bordered
          rowKey={(record: CommentTableRow) =>
            record.replyId ? `replyComment_${record.replyId}` : `comment_${record.id}`
          }
          key={'comment_manager'}
          expandable={{
            defaultExpandAllRows: true,
          }}
        />
      </Card>
    </>
  );
};

export default CommentManagerPage;
